import React from "react";
import "./LoginFormStyles.scss";

import { Button, Typography, TextField, Box } from "@material-ui/core";

import GoogleIcon from "../../assets/google-icon.png";

const LoginForm = ({
  hasAccount,
  setName,
  name,
  setEmail,
  email,
  setPassword,
  password,
  handleSignup,
  handleLogin,
  googleSignIn,
  setHasAccount,
}) => {
  return (
    <div className='form'>
      {hasAccount && (
        <Typography variant='h5' color='primary'>
          Sign In
        </Typography>
      )}
      {!hasAccount && (
        <Typography variant='h5' color='primary'>
          Register an Account
        </Typography>
      )}
      <div
        style={{
          height: 2,
          backgroundColor: "#eee",
          marginBottom: 20,
          marginTop: 8,
        }}
      />
      <div className='inputs-bar'>
        {!hasAccount && (
          <TextField
            style={{ marginLeft: 8 }}
            onChange={(e) => setName(e.target.value)}
            variant='outlined'
            color='primary'
            label='Name'
            type='text'
            value={name}
          />
        )}
        <TextField
          style={{ marginLeft: 8 }}
          onChange={(e) => setEmail(e.target.value)}
          variant='outlined'
          color='primary'
          label='Email'
          type='email'
          value={email}
        />
        <TextField
          style={{ marginLeft: 8 }}
          onChange={(e) => setPassword(e.target.value)}
          variant='outlined'
          color='primary'
          label='Password'
          type='password'
          value={password}
        />
        {!hasAccount ? (
          <Button
            style={{ marginLeft: 8 }}
            onClick={handleSignup}
            variant='contained'
            color='primary'
          >
            Sign up{" "}
          </Button>
        ) : (
          <Button
            style={{ marginLeft: 8 }}
            onClick={handleLogin}
            variant='contained'
            color='primary'
          >
            Login
          </Button>
        )}
      </div>
      <Box
        mt={2}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Typography>or</Typography>

        {hasAccount ? (
          <Button variant='contained' onClick={() => setHasAccount(false)}>
            Or click here to create an account.
          </Button>
        ) : (
          <Button variant='contained' onClick={() => setHasAccount(true)}>
            If you have an account, click here to sign in.
          </Button>
        )}
        <Typography>or</Typography>
        <Button
          fullWidth
          style={{ marginBottom: 16, marginTop: 16 }}
          onClick={googleSignIn}
          variant='contained'
        >
          <img src={GoogleIcon} style={{ width: 20, marginRight: 8 }} />
          Sign in with Google
        </Button>
      </Box>
      <Box className='disclaimer'>
        <Typography variant='subtitle2'>
          Upon registration, your email address will be automatically added to
          our mailing list. After which you may choose to unsubscribe at any
          time.{" "}
        </Typography>
      </Box>
    </div>
  );
};

export default LoginForm;
