import React, { useState, useEffect } from "react";
import MembershipForm from "../../components/MembershipForm";
import useFirebase from "../../hooks/useFirebase";
import "./styles.scss";
import { useSelector } from "react-redux";

const AdminPage = () => {
  const { members } = useSelector((state) => state);
  const {
    createUser,
    removeMember,
    createSubscription,
    fetchUsers,
  } = useFirebase();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pageAccess, setPageAccess] = useState(false);
  const [adminPwd, setAdminPwd] = useState("");
  const [createMode, setCreateMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const addMembership = (subscription) => {
    console.log(subscription);
    createSubscription(subscription);
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  console.log(members);

  useEffect(() => {
    setPageAccess(adminPwd === "HKSNA" ? true : false);
  }, [adminPwd]);

  return (
    <div>
      <h1>Admin</h1>
      {!pageAccess && (
        <div>
          <input onChange={(e) => setAdminPwd(e.target.value)} />
        </div>
      )}
      {pageAccess && (
        <div>
          <h3>Create Auth Login for Member</h3>
          <input
            className='admin-input'
            placeholder='Name'
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className='admin-input'
            placeholder='Email'
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className='admin-input'
            placeholder='Password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className='admin-button'
            onClick={() => createUser(email, password, name)}
          >
            Add User
          </button>
          <hr />
          <div className='admin-button-container'>
            <button
              onClick={() => {
                setCreateMode(true);
                setDeleteMode(false);
              }}
              className='admin-button create'
            >
              Create Membership Profile
            </button>
            <button
              onClick={() => {
                setDeleteMode(true);
                setCreateMode(false);
              }}
              className='admin-button delete'
            >
              Delete Membership Profile
            </button>
          </div>

          {createMode && (
            <div>
              <div style={{ width: "70%", margin: "auto" }}>
                <p>
                  This will create a HKSNA profile for {name}, using email
                  address {email}
                </p>
                <p>
                  Membership createdAt date will be set to today. Therefore,
                  their membership renewal prompt will occur one year from
                  today.
                </p>
              </div>
              <MembershipForm admin addSubscription={addMembership} />
            </div>
          )}
          {deleteMode && (
            <div>
              <h3>Delete a HKSNA Member Profile</h3>
              {members.map((m) => (
                <div
                  style={{
                    width: 300,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>{m.name}</p>
                  <p
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => {
                      removeMember(m);
                    }}
                  >
                    x
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminPage;
