import React, { useState, useEffect } from "react";
import { getCompetitions } from "../../contentful";
import { Link } from "react-router-dom";
import "./CompetitionsPageStyles.scss";

import { Typography, Button } from "@material-ui/core";

import PageTitle from "../../components/PageTitle";
import CompetitionsApplicationModal from "../../components/CompetitionsApplicationModal/CompetitionsApplicationModal";
import Helmet from "../../components/Helmet/Helmet";

const CompetitionsPage = () => {
  const [comps, setComps] = useState([]);
  const [compToApply, setCompToApply] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCompetitions().then((data) => setComps(data));
  }, []);

  const buttonStyle = {
    margin: 4,
  };
  return (
    <div className='container'>
      <Helmet title='HKSNA | Competitions' />
      <PageTitle title='Competitions' />
      {comps.map((comp, index) => (
        <div className='competition-card'>
          <Typography variant='h5'>{comp.fields.title}</Typography>
          <Typography variant='h6' color='secondary'>
            {comp.fields.datesPeriod}
          </Typography>
          <div className='competition-card__button'>
            <Link to={`/competitions/${comp.sys.id}`}>
              <Button style={buttonStyle} variant='contained' color='primary'>
                More Information
              </Button>
            </Link>

            <Button
              style={buttonStyle}
              variant='outlined'
              color='primary'
              onClick={() => {
                setCompToApply(comp);
              }}
            >
              Apply Here
            </Button>
            {compToApply && (
              <CompetitionsApplicationModal
                open={compToApply}
                onClose={() => {
                  setCompToApply(null);
                }}
                competition={compToApply}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompetitionsPage;
