import React, { useState, useEffect } from "react";
import "./HomepageStyles.scss";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";
import { Link } from "react-router-dom";
import {
  getCallForProposals,
  getConferences,
  getHomepage,
} from "../../contentful";
import marked from "marked";
import ConferenceIntroductionCard from "../../components/ConferenceIntroductionCard";

import { Button, Typography } from "@material-ui/core";

import HomeHero from "../../components/HomeHero/HomeHero";
import PageTitle from "../../components/PageTitle";

const Homepage = () => {
  const today = new Date();
  const dispatch = useDispatch();
  const [call, setCall] = useState({});
  const [conferences, setConferences] = useState([]);
  const [homepage, setHomepage] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getCallForProposals().then((data) => setCall(data[0].fields));
    getConferences().then((data) =>
      setConferences(data.filter((item) => item.fields.showOnHomepage))
    );

    getHomepage().then((data) => {
      console.log(data);
      setHomepage(data[0].fields);
    });
  }, []);

  const buttonStyle = {
    margin: 4,
  };

  return (
    <div className='homepage-container container'>
      <PageTitle title='Welcome to HKSNA' />
      {homepage.text && homepage.welcomeImage && (
        <div className='homepage__intro'>
          <div className='homepage__intro__text'>
            <Typography
              color='primary'
              dangerouslySetInnerHTML={{ __html: marked(homepage?.text) }}
            />
          </div>
          <div className='homepage__intro__image'>
            <img src={homepage.welcomeImage.fields.file.url} />
          </div>
        </div>
      )}
      {conferences &&
        conferences
          .filter((c) => !c.fields.archived)
          .map((con) => (
            <ConferenceIntroductionCard
              key={con.sys.id}
              conference={con.fields}
              id={con.sys.id}
            />
          ))}
      <div className='banner'>
        <Typography color='primary'>
          Coming soon: The membership area, containing members-only resources,
          information and discounts.
        </Typography>
      </div>
      <Typography color='primary' variant='h5'>
        Past Events
      </Typography>
      {conferences &&
        conferences
          .filter((c) => c.fields.archived)
          .map((con) => (
            <ConferenceIntroductionCard
              key={con.sys.id}
              conference={con.fields}
              id={con.sys.id}
            />
          ))}
    </div>
  );
};

export default Homepage;
