import React from "react";
import "./PDFDownloadItem.scss";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { Typography } from "@material-ui/core";

const PDFDownloadItem = ({ text, file }) => {
  return (
    <a className='download-link' href={file} download target='_blank'>
      <div
        style={{
          display: "flex",
        }}
      >
        <Typography>{text}</Typography>
        <div style={{ marginLeft: 8 }}>
          <DownloadIcon />
        </div>
      </div>
    </a>
  );
};

export default PDFDownloadItem;
