import React, { useState, useEffect } from "react";
import { getConferences } from "../../contentful";
import { Typography } from "@material-ui/core";
import "./ConferencesPageStyles.scss";
import { Link } from "react-router-dom";
import moment from "moment";

import PageTitle from "../../components/PageTitle";
import Helmet from "../../components/Helmet";
import ConferenceIntroductionCard from "../../components/ConferenceIntroductionCard";

const ConferencesPage = () => {
  const [conferences, setConferences] = useState([]);
  const today = new Date();

  useEffect(() => {
    window.scrollTo(0, 0);
    getConferences().then((data) => setConferences(data));
  }, []);
  return (
    <div className='container'>
      <Helmet title={`HKSNA | Conferences `} />

      <PageTitle title='Conferences' />
      {/* <Typography>
        Welcome to the conferences page. Here you will find all of our
        forthcoming conferences listed. Where appropriate there are links to
        further details and you will be able to submit proposals to participate.
      </Typography> */}
      {conferences
        .filter((conference) => !conference.fields.archived)
        .map((conference) => (
          <ConferenceIntroductionCard
            key={conference.sys.id}
            conference={conference.fields}
            id={conference.sys.id}
          />
        ))}
      <hr />
      <Typography color='primary' variant='h4'>
        Past Events
      </Typography>
      <Typography>
        Click on the titles to visit the details page, download programme books,
        watch videos and see photos from this event.
      </Typography>
      {conferences
        .filter((event) => event.fields.archived)
        .map((event) => (
          <Link
            key={event.sys.id}
            style={{ textDecoration: "none" }}
            to={`/conferences/${event.sys.id}`}
          >
            <div className='past-event-list-item'>
              <div style={{ marginRight: 16 }}>
                <Typography variant='h6' color='secondary'>
                  {/* {moment(event.fields.startDate).format("MMMM Do YYYY")} */}
                  {event.fields?.startDate}
                </Typography>
              </div>
              <Typography variant='h6'>{event.fields.title}</Typography>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default ConferencesPage;
