import React from "react";
import { Typography } from "@material-ui/core";

const RadioGroup = ({
  containerClassnames,
  inputClassnames,
  text1,
  text2,
  items,
  onChange,
  groupName,
}) => {
  if (!items || items.length === 0) return null;
  return (
    <>
      <Typography color='primary'>{text1}</Typography>
      <Typography>{text2}</Typography>
      <div className='competition-form__radio-group'>
        {items?.map((item) => (
          <div className='competition-form__radio'>
            <label>
              <input
                type='radio'
                name={groupName}
                value={item.value || item}
                onChange={onChange}
              />
              <span>{item.label || item}</span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default RadioGroup;
