import React from "react";
import "./globalStyles.scss";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AppBar from "./components/Appbar";
import SideDrawer from "./components/SideDrawer";
import ContactModal from "./components/ContactModal";
import Footer from "./components/Footer";
import Homepage from "./pages/Homepage/Homepage";
import ContactPage from "./pages/ContactPage";
import ProposalsModal from "./components/ProposalsModal";
import ThankyouPage from "./pages/ThankyouPage";
import ConferencesPage from "./pages/Conferences/ConferencesPage";
import ConferenceDetailsPage from "./pages/ConferenceDetailsPage/ConferenceDetailsPage";
import MembershipPage from "./pages/MembershipPage";
import Alerts from "./components/Alerts/Alerts";
import MembersArea from "./pages/MembersArea";
import NewslettersPage from "./pages/NewslettersPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import ConferenceRegistrationModal from "./components/ConferenceRegistrationModal";
import CompetitionsPage from "./pages/CompetitionsPage/CompetitionsPage";
import CompetitionsDetailsPage from "./pages/CompetitionsDetailsPage";
import NotFound from "./pages/404Page";
import JournalPage from "./pages/JournalPage";
import DonationsModal from "./components/DonationsModal/DonationsModal";
import useFirebase from "./hooks/useFirebase";
import LoginPage from "./pages/LoginPage/LoginPage";
import GPDRModal from "./components/GDPRModal";
import AdminPage from "./pages/AdminPage";
import DonationsBanner from "./components/DonationsBanner/DonationsBanner";
import Journals from "./pages/Journals/Journals";
import Scholarships from "./pages/Scholarships/Scholarships";
import ScholarshipsDetailsPage from "./pages/Scholarships/ScholarshipsDetailsPage";

const App = () => {
  const { paidUser } = useSelector((state) => state);

  const routes = paidUser ? (
    <Switch>
      <Route path='/' exact component={Homepage} />
      <Route path='/contact' component={ContactPage} />
      <Route path='/about' component={AboutPage} />
      <Route path='/thankyou' component={ThankyouPage} />
      <Route path='/journal' exact component={JournalPage} />
      <Route path='/scholarships/:id' component={ScholarshipsDetailsPage} />
      <Route path='/scholarships' component={Scholarships} />
      <Route path='/conferences/:id' component={ConferenceDetailsPage} />
      <Route path='/conferences' component={ConferencesPage} />
      <Route path='/competitions/:id' component={CompetitionsDetailsPage} />
      <Route path='/competitions' component={CompetitionsPage} />
      <Route path='/membership' component={MembershipPage} />
      <Route path='/members_area' component={MembersArea} />
      <Route path='/newsletters' component={NewslettersPage} />

      <Route path='/login' component={LoginPage} />
      <Route path='/admin' component={AdminPage} />
      <Route path='/journal-index' component={Journals} />

      <Route component={NotFound} />
    </Switch>
  ) : (
    <Switch>
      <Route path='/' exact component={Homepage} />
      <Route path='/contact' component={ContactPage} />
      <Route path='/about' component={AboutPage} />

      <Route path='/thankyou' component={ThankyouPage} />
      <Route path='/scholarships/:id' component={ScholarshipsDetailsPage} />
      <Route path='/scholarships' component={Scholarships} />
      <Route path='/conferences/:id' component={ConferenceDetailsPage} />
      <Route path='/conferences' component={ConferencesPage} />
      <Route path='/competitions/:id' component={CompetitionsDetailsPage} />
      <Route path='/competitions' component={CompetitionsPage} />
      <Route path='/membership' component={MembershipPage} />
      <Route path='/journal' exact component={JournalPage} />
      <Route path='/newsletters' component={NewslettersPage} />
      <Route path='/login' component={LoginPage} />
      <Route path='/admin' component={AdminPage} />
      <Route path='/journal-index' component={Journals} />

      <Route component={NotFound} />
    </Switch>
  );
  return (
    <div>
      <AppBar />
      <SideDrawer />
      <ContactModal />
      <ProposalsModal />
      <GPDRModal />
      <ConferenceRegistrationModal />
      <DonationsModal />
      <Alerts />
      <div className='app-container'>{routes}</div>
      <DonationsBanner />
      <Footer />
    </div>
  );
};

export default App;
