import React, { useState } from "react";
import "./ContactPageStyles.scss";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Box } from "@material-ui/core";

import ContactForm from "../../components/ContactForm";
import PageTitle from "../../components/PageTitle";

const ContactPage = () => {
  return (
    <div className='container contact-page-container'>
      <PageTitle title='Contact Us' />
      <Box mt={2}>
        <Typography>
          If you have questions or special inquiries, you're welcome to contact
          us by filling out this form. Please select the appropriate recipient.
        </Typography>
      </Box>
      <ContactForm multipleRecipients modal={false} />
    </div>
  );
};

export default ContactPage;
