import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import marked from "marked";
import { getScholarship } from "../../contentful";
import "./ScholarshipsStyles.scss";

import PageTitle from "../../components/PageTitle";
import Helmet from "../../components/Helmet";

const ScholarshipDetailsPage = () => {
  const [content, setContent] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getScholarship(id).then((data) => {
      console.log(data);
      setContent(data?.[0]?.fields);
    });
  }, [id]);

  if (!content) return null;
  const { title, mainText } = content;
  console.log(content);

  return (
    <div className='container'>
      <Helmet title={`HKSNA | ${content?.title} `} />
      <PageTitle title={title} />
      {mainText && (
        <section dangerouslySetInnerHTML={{ __html: marked(mainText) }} />
      )}
    </div>
  );
};

export default ScholarshipDetailsPage;
