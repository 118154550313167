import React from "react";
import "./DonationsBannerStyles.scss";

import { Typography, Button, Box } from "@material-ui/core";

const DonationsBanner = () => (
  <div className='banner donations-banner'>
    <Typography color='primary'>
      We value donations of all sizes. You can easily make a donation using
      credit card or Paypal here...
    </Typography>
    <Box mt={2}>
      <form
        action='https://www.paypal.com/donate'
        method='post'
        target='_blank'
      >
        <input type='hidden' name='hosted_button_id' value='6KW6VNVW32REA' />

        <Button type='submit' variant='contained' color='primary'>
          Make a donation
        </Button>
      </form>
    </Box>
  </div>
);

export default DonationsBanner;
