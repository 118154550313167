import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import "./GalleryStyles.scss";

const Gallery = ({ images }) => {
  const photoClasses = ["", "big", "tall", "wide"];

  return (
    <SRLWrapper>
      <div className='grid-wrapper'>
        {images &&
          images.map((p) => {
            const randomIdx = Math.floor(Math.random() * 3);

            return (
              <div
                key={p.fields.file.url}
                className={`${photoClasses[randomIdx]} image-container`}
              >
                {" "}
                <img src={p.fields.file.url} alt={p.fields.description} />
                {p.fields.description && (
                  <div className='image-container__description'>
                    {/* <span>{p.fields.description}</span> */}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </SRLWrapper>
  );
};

export default Gallery;
