import React, { useEffect, useState } from "react";
import "./GDPRModalStyles.scss";
import { getPrivacy } from "../../contentful";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";
import { Dialog, Typography, Button } from "@material-ui/core";
import marked from "marked";

const GPDRDialog = () => {
  const [policy, setPolicy] = useState("");
  const dispatch = useDispatch();
  const { GDPRModal } = useSelector((state) => state);

  useEffect(() => {
    getPrivacy().then((data) => setPolicy(data[0].fields.text));
  }, []);

  return (
    <Dialog
      open={GDPRModal}
      onClose={() => dispatch({ type: ACTIONTYPES.TOGGLE_GDPR_MODAL })}
    >
      {policy && (
        <Typography
          className='privacy-modal'
          dangerouslySetInnerHTML={{ __html: marked(policy) }}
        />
      )}
      <Button
        onClick={() => dispatch({ type: ACTIONTYPES.TOGGLE_GDPR_MODAL })}
        style={{ margin: 18 }}
        color='primary'
        variant='outlined'
      >
        Close
      </Button>
    </Dialog>
  );
};

export default GPDRDialog;
