import React from "react";
import "./MemberDetailsModal.scss";
import { Typography, Dialog } from "@material-ui/core";

const MemberDetailsModal = ({ open, onClose, member }) => {
  // console.log(member);
  return (
    <Dialog open={open} onClose={onClose}>
      {member && (
        <div className='details-modal'>
          {member.avatar && (
            <div className='details-modal__avatar'>
              {" "}
              <img src={member.avatar} />
            </div>
          )}
          <Typography variant='h4'>{member.name}</Typography>
          <hr />

          <a href={`mailto:${member.email}`}>
            <Typography variant='body1'>{member.email}</Typography>
          </a>
          <p className='details-modal__list-title'>Location</p>
          <p>
            {member.address1}
            {member.address2 && (
              <>
                {" "}
                <br />
                <span>{member.address2}</span>
              </>
            )}
            <br />
            {member.city}
            <br />
            {member.state}
          </p>
          <p className='details-modal__list-title'>Instrument(s) </p>
          <p>{member.instruments}</p>
          {member.teacher && (
            <p className='details-modal__list-title'>Activities</p>
          )}
          {member.teacher && <p>Teacher</p>}
          {member.maker && <p>Maker</p>}
          <p className='details-modal__list-title'>Biography</p>
          <p>{member.biog}</p>
        </div>
      )}
    </Dialog>
  );
};

export default MemberDetailsModal;
