import React, { useState } from "react";
import "./SideDrawerStyles.scss";
import { Drawer, Typography, Menu, MenuItem, IconButton } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../constants/navigation";
import logo from "../../assets/logo.jpg";

const SideDrawer = () => {
  const { sideDrawer, user, paidUser, loginArea } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [pubAnchorEl, setPubAnchorEl] = useState(null);

  const openPubTag = Boolean(pubAnchorEl);
  const handlePubClick = (event) => {
    setPubAnchorEl(event.currentTarget);
  };
  const handlePubClose = () => {
    setPubAnchorEl(null);
  };

  const handleDropdownClick = () => {
    setPubAnchorEl(false);
    dispatch({ type: ACTIONTYPES.TOGGLEDRAWER });
  };

  return (
    <Drawer open={sideDrawer} onClose={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })}>
      <div className='drawer-container'>
        <div className='close-icon'>
          <IconButton onClick={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })}>
            <CloseRounded />
          </IconButton>
        </div>
        <NavLink onClick={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })} to='/'>
          <img src={logo} className='logo' />
        </NavLink>
        <div className='nav-menu'>
          {ROUTES.map((route) => (
            <NavLink
              onClick={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })}
              className='link'
              key={route.path}
              to={route.path}
            >
              {route.label}
            </NavLink>
          ))}
          <NavLink
            onClick={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })}
            className='link'
            to='/competitions/6iGWylf43eArgPGfPT0FPu'
          >
            Alienor Competition{" "}
          </NavLink>
          <NavLink
            onClick={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })}
            className='link'
            to='/competitions/2BcoXNC3EoeV14FgsLgaNZ'
          >
            Jurow Competition
          </NavLink>
          <span className='link dropdown-tag ' onClick={handlePubClick}>
            Publications
          </span>
          <Menu anchorEl={pubAnchorEl} open={openPubTag} onClose={handlePubClose}>
            <MenuItem>
              <NavLink onClick={handleDropdownClick} className='dropdown-tag' to='/journal'>
                Early Keyboard Journal
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink onClick={handleDropdownClick} className='dropdown-tag' to='/journal-index'>
                Journal Index
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink onClick={handleDropdownClick} className='dropdown-tag' to='/newsletters'>
                Newsletter
              </NavLink>
            </MenuItem>
          </Menu>
          <NavLink onClick={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })} className='link' to='/membership'>
            Membership
          </NavLink>
          <NavLink className='link' to='/contact'>
            Contact
          </NavLink>

          {paidUser && (
            <NavLink
              onClick={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })}
              style={{ color: "green" }}
              className='link'
              to='/members_area'
            >
              Members Area
            </NavLink>
          )}
          {!user && loginArea && (
            <NavLink onClick={() => dispatch({ type: ACTIONTYPES.TOGGLEDRAWER })} className='link' to='/login'>
              Login
            </NavLink>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default SideDrawer;
