import React from "react";
import { Snackbar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";

const Alerts = () => {
  const dispatch = useDispatch();
  const { errorMessage, successMessage } = useSelector((state) => state);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: ACTIONTYPES.SET_ERROR_MESSAGE, payload: null });
    dispatch({ type: ACTIONTYPES.SET_SUCCESS_MESSAGE, payload: null });
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={errorMessage}
        onClose={handleClose}
        autoHideDuration={6000}
        style={{
          backgroundColor: "darkred",
          color: "white",
          borderRadius: 12,
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <p> {errorMessage}</p>
      </Snackbar>
      <Snackbar
        style={{
          backgroundColor: "green",
          color: "white",
          borderRadius: 12,
          paddingLeft: 5,
          paddingRight: 5,
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={successMessage}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <p> {successMessage}</p>
      </Snackbar>
    </>
  );
};

export default Alerts;
