import React, { useEffect, useState } from "react";
import "./ConferenceIntroductionCardStyles.scss";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import marked from "marked";

import { ACTIONTYPES } from "../../store/ActionTypes";
import { Button, Typography } from "@material-ui/core";
import Gallery from "../Gallery/Gallery";

const ConferenceIntroductionCard = ({ conference, id }) => {
  const dispatch = useDispatch();
  const [showGallery, setShowGallery] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setShowGallery(location.pathname.includes("conferences") ? false : true);
  }, [location]);

  return (
    <div className='conference-card-container'>
      {conference.image && (
        <div className='conference-card__mobile-image'>
          <div className='mobile-image-container'>
            <img src={conference.image.fields.file.url} />
          </div>
        </div>
      )}{" "}
      <div className='conference-card'>
        {conference.image && (
          <img
            src={conference.image.fields.file.url}
            className='conference-card__image'
          />
        )}
        <div style={{ width: "100%" }}>
          <Typography variant='h5' color='secondary'>
            {/* {moment(conference.startDate).format("ddd MMM Do")} */}
            {conference?.startDate}
            {conference.endDate && (
              <span>
                - {conference?.endDate}
                {/* - {moment(conference.endDate).format("ddd MMM Do YYYY")} */}
              </span>
            )}
          </Typography>

          <Typography className='conference-card__title' variant='h4'>
            {conference.title}
          </Typography>
          {conference.venue && (
            <Typography
              className='conference-card__venue'
              dangerouslySetInnerHTML={{ __html: marked(conference.venue) }}
            />
          )}

          {conference.inviteProposals && conference.callForProposalsText && (
            <Typography
              className='conference-card__venue'
              dangerouslySetInnerHTML={{
                __html: marked(conference.callForProposalsText),
              }}
            />
          )}

          <Typography
            variant='body1'
            dangerouslySetInnerHTML={{ __html: marked(conference.description) }}
          />
          {conference.showOnHomepage && conference.photoGallery && showGallery && (
            <div style={{ marginBottom: 30 }}>
              <Gallery images={conference.photoGallery} />
            </div>
          )}

          <div style={{ textAlign: "right" }}>
            <div className='buttons-container'>
              {(conference.details || conference.dailyInformation) && (
                <Link
                  className='button button--primary'
                  to={`/conferences/${id}`}
                >
                  More information
                  {/* <Typography color='primary'>More information</Typography> */}
                </Link>
              )}
              {conference.inviteProposals && (
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() =>
                    dispatch({
                      type: ACTIONTYPES.TOGGLE_SUBMISSIONS_MODAL,
                      payload: conference,
                    })
                  }
                >
                  Submit a Proposal
                </Button>
              )}
              {!conference.archived && (
                <Button
                  color='primary'
                  variant='outlined'
                  style={{ marginLeft: 8 }}
                  onClick={() =>
                    dispatch({
                      type: ACTIONTYPES.TOGGLE_CONFERENCE_REGISTRATION_MODAL,
                      payload: conference,
                    })
                  }
                >
                  Register
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceIntroductionCard;
