import React from "react";
import "./ContactModalStyles.scss";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";
import { Dialog, Typography, IconButton } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import ContactForm from "../ContactForm";

const ContactModal = () => {
  const dispatch = useDispatch();
  const { contactModal } = useSelector((state) => state);
  return (
    <Dialog
      open={contactModal}
      onClose={() => dispatch({ type: ACTIONTYPES.TOGGLE_CONTACT_MODAL })}
    >
      <div className='container'>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant='h4' color='primary'>
            Contact Us
          </Typography>
          <IconButton
            onClick={() => dispatch({ type: ACTIONTYPES.TOGGLE_CONTACT_MODAL })}
          >
            <Close />
          </IconButton>
        </div>

        <ContactForm
          multipleRecipients
          modal
          onClose={() => dispatch({ type: ACTIONTYPES.TOGGLE_CONTACT_MODAL })}
        />
      </div>
    </Dialog>
  );
};

export default ContactModal;
