import React from "react";
import { TextField, Typography } from "@material-ui/core";

const TextInput = ({
  onChange,
  name,
  label,
  text1 = "",
  text2 = "",
  value,
}) => {
  return (
    <>
      {text1 && <Typography color='primary'>{text1}</Typography>}
      {text2 && <Typography>{text2}</Typography>}
      <div className='input'>
        <TextField
          name={name}
          className='competition-form__input'
          variant='outlined'
          label={label}
          onChange={onChange}
          value={value}
        />
      </div>
    </>
  );
};

export default TextInput;
