import React from "react";
import useContentful from "../../hooks/useContentful";
import { getJournalpage } from "../../contentful";
import "./JournalPageStyles.scss";

import Helmet from "../../components/Helmet";
import PageTitle from "../../components/PageTitle";
import marked from "marked";

const JournalPage = () => {
  const { content } = useContentful(getJournalpage);
  const page = content?.[0]?.fields;

  return (
    <div className='container'>
      <Helmet title={`HKSNA | Journals `} />
      <PageTitle title={page?.title} />
      <div className='journals'>
        {page?.image2 && (
          <div className='journals__image__wrapper journals__image__wrapper--centered journals__image__wrapper--bottom image2'>
            {" "}
            <img className='journals__image' src={page.image2.fields.file.url} />
          </div>
        )}

        {page?.text1 && (
          <div className='journals__text text1' dangerouslySetInnerHTML={{ __html: marked(page.text1) }} />
        )}

        {page?.text2 && (
          <div className='journals__text text2' dangerouslySetInnerHTML={{ __html: marked(page.text2) }} />
        )}
        {page?.image1 && (
          <div className='journals__image__wrapper journals__image__wrapper--top image1'>
            {" "}
            <img className='journals__image ' src={page.image1.fields.file.url} />
          </div>
        )}
      </div>
    </div>
  );
};

export default JournalPage;
