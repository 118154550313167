import React, { useState, useEffect } from "react";

const useContentful = (prom) => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    prom().then((data) => setContent(data));
  }, []);

  return { content };
};

export default useContentful;
