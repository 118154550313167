import React, { useEffect, useState } from "react";
import "./TableStyles.scss";

const Table = ({ table }) => {
  const [headers, setHeader] = useState([]);
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (table) {
      const content = table.content;
      const t = content.find((c) => c.nodeType === "heading-3").content[0]
        .value;
      setTitle(t);
      const headers = content
        ?.find((c) => c.nodeType === "table")
        ?.content?.[0]?.content?.map(
          (item) => item.content[0].content[0].value
        );
      setHeader(headers);
      const rowsData = content
        ?.find((c) => c.nodeType === "table")
        ?.content?.slice(1);
      const rows = rowsData.map((row) =>
        row.content.map((cell) => cell.content[0].content[0].value)
      );
      setRows(rows);
    }
  }, [table]);
  return (
    <div>
      <h3 className='table__title'>{title}</h3>
      <table className='table'>
        <thead>
          <tr>
            {headers.map((h) => (
              <th key={h}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((r, i) => (
            <tr key={`row-${i}`}>
              {r.map((c, i) => (
                <td key={c + i}>{c}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
