import React from "react";
import "./CompetitionsApplicationModalStyles.scss";

import { Dialog, Typography } from "@material-ui/core";

const CompetitionsApplicationModal = ({ competition, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: 35 }}>
        <Typography variant='h4' color='primary'>
          Application Form
        </Typography>
        {/* <Typography>{competition.fields.title}</Typography> */}
        <hr />
        <Typography>Application form: which details?</Typography>
        <Typography>
          How much to apply? is same for all competitions?
        </Typography>
        <h4>PAYPAL button</h4>
      </div>
    </Dialog>
  );
};

export default CompetitionsApplicationModal;
