import { createStore } from "redux";
import { ACTIONTYPES } from "./ActionTypes";
const initialState = {
  name: "HKSNA",
  sideDrawer: false,
  contactModal: false,
  submissionModal: false,
  selectedConference: null,
  user: null,
  paidUser: null,
  loading: false,
  errorMessage: null,
  successMessage: null,
  accountExpired: true,
  conferenceRegistration: false,
  donationsModal: false,
  paypalClientId:
    "AdB6rjRtEy3mUYLOAY8Ujvk0CR-szw_k2RDPCGN9Nt4579FDFSFGUyGDZ8rd_J-yg9pr_3vpwrfJCD1b",
  //sandbox paypalClientId:
  //   "AYT7Bs4cCGdNf9SjVnZuzklx2_ZWNSurgntuCGMkn8tHrvHhpz71_rZqlmZKMxhnYKdR8cjExAd6FUIh",
  members: [],
  GDPRModal: false,
  loginArea: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONTYPES.TOGGLEDRAWER:
      return {
        ...state,
        sideDrawer: !state.sideDrawer,
      };
    case ACTIONTYPES.TOGGLE_CONTACT_MODAL:
      return {
        ...state,
        contactModal: !state.contactModal,
      };
    case ACTIONTYPES.TOGGLE_SUBMISSIONS_MODAL:
      return {
        ...state,
        submissionModal: !state.submissionModal,
        selectedConference: action.payload,
      };
    case ACTIONTYPES.TOGGLE_CONFERENCE_REGISTRATION_MODAL:
      return {
        ...state,
        conferenceRegistration: !state.conferenceRegistration,
        selectedConference: action.payload,
      };
    case ACTIONTYPES.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ACTIONTYPES.SET_PAID_USER:
      return {
        ...state,
        paidUser: { ...action.payload, ...state.user },
      };
    case ACTIONTYPES.LOGOUT_PAID_USER:
      return {
        ...state,
        paidUser: null,
      };
    case ACTIONTYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONTYPES.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case ACTIONTYPES.SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ACTIONTYPES.ACCOUNT_EXPIRED:
      return {
        ...state,
        accountExpired: action.payload,
      };
    case ACTIONTYPES.TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case ACTIONTYPES.TOGGLE_DONATIONS_MODAL:
      return {
        ...state,
        donationsModal: !state.donationsModal,
      };
    case ACTIONTYPES.SET_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case ACTIONTYPES.TOGGLE_GDPR_MODAL:
      return {
        ...state,
        GDPRModal: !state.GDPRModal,
      };
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
