import React, { useState, useEffect } from "react";
import { getAboutpageContent } from "../../contentful";
import marked from "marked";
import "./AboutPageStyles.scss";

import { Typography } from "@material-ui/core";

import PageTitle from "../../components/PageTitle";
import Helmet from "../../components/Helmet";

const AboutPage = () => {
  const [contents, setContent] = useState(null);

  useEffect(() => {
    getAboutpageContent().then((data) => setContent(data[0].fields));
  }, []);

  const content = contents ? (
    <div>
      <section className='about-page-section'>
        <div className='about-page-section__image'>
          <img src={contents.image1.fields.file.url} />
        </div>{" "}
        <Typography
          className='about-page-section__text'
          dangerouslySetInnerHTML={{ __html: marked(contents.text1) }}
        />
      </section>
      <section className='about-page-section'>
        <Typography
          className='about-page-section__text'
          dangerouslySetInnerHTML={{ __html: marked(contents.text2) }}
        />
        <div className='about-page-section__image'>
          <img src={contents.image2.fields.file.url} />
        </div>
      </section>
    </div>
  ) : null;

  return (
    <div className='container'>
      <Helmet title='HKSNA | About' />

      <PageTitle title='About HKSNA' />
      {content}
    </div>
  );
};

export default AboutPage;
