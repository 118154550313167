import React, { useState, useEffect } from "react";
import "./CompetitionApplicationFormStyles.scss";
import { useSelector } from "react-redux";
import PayPalButton from "react-paypal-smart-button";

import { Box, TextField, Typography } from "@material-ui/core";
import Spinner from "../../components/Spinner/Spinner";
import useSendEmail from "../../hooks/useSendEmail";
import ReCaptcha from "../../components/ReCaptcha/ReCaptcha";
import { getContactDestinations } from "../../contentful";
import RadioGroup from "../../components/RadioGroup/RadioGroup";
import TextInput from "../../components/TextInput/TextInput";

const CompetitionApplicationForm = () => {
  const { loading, paypalClientId } = useSelector((state) => state);

  const { sendMail, sent } = useSendEmail();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [street1, setStreet1] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [nationality, setNationality] = useState("");
  const [dob, setDob] = useState("");
  const [recaptcha, setRecaptcha] = useState(false);
  const [bach, setBach] = useState(null);
  const [scarlatti, setScarlatti] = useState(null);
  const [isStudent, setIsStudent] = useState(false);
  const [recentTeacher, setRecentTeacher] = useState("");
  const [folderLink, setFolderLink] = useState("");

  const [recipients, setRecipients] = useState("secretary@hksna.org");

  useEffect(() => {
    getContactDestinations().then((data) => {
      const contactForm = data.find(
        (data) => data.fields.place === "Jurow Application"
      );
      if (contactForm) {
        setRecipients(contactForm?.fields.email?.join(", "));
        // setOfflineFormUrl(contactForm?.fields.form?.fields.file.url);
      }
    });
  }, []);

  // if there is an & in the string, it breaks the query string parsing
  const replaceAmpersand = (string) => string.replace("&", "and");

  const subject = `Jurow competition application from ${firstName} ${lastName} `;

  const body2 = `<div><h4>Jurow Competition application.</h4><p><b>Name:</b> ${firstName} ${lastName}</p><p><b>Email:</b> ${email}</p><p><b>DOB:</b> ${dob}</p><b>Nationality:</b> ${nationality}<p><b>Address:</b></p><p>${street1}</p><p>${city}</p><p>${province}</p><p>${postcode}</p><p>${country}</p><p><b>Media folder:</b> ${folderLink}</p><p><b>Bach:</b> ${bach}</p><p><b>Scarlatti:</b> ${scarlatti}</p><b><p>Student?</b> ${isStudent}</p><p><b>Recent teachers:</b> ${recentTeacher}</p><hr/><p>Payment by Paypal has been made for the amount of $150</p><p></p></div>`;

  const confirmationBody = `<div><p>Dear ${firstName} ${lastName}.</p><p>Many thanks for your application to the HKSNA Jurow Competition.  If you paid via Paypal, you should receive an email from Paypal to confirm your payment was recieved.</p><p><b>To complete the application, you will need to:</b></p><p><b>Pour remplir votre candidature, vous avez besoin de:</b></p><ul><li>Send a photocopy of your passport or birth certificate, showing your name, date of birth, and nationality, to jurow@hksna.org.</li><li>Envoyez une photocopie de votre passeport ou acte de naissance, indiquant votre nom, votre date de naissance et votre nationalité, à jurow@hksna.org.</li></ul></p><p>Should you have any queries, please reach out to us via email at jurow@hksna.org</p></div>`;

  const readyToSend = () =>
    firstName &&
    lastName &&
    email &&
    recaptcha &&
    dob &&
    nationality &&
    street1 &&
    city &&
    province &&
    postcode &&
    country &&
    folderLink &&
    bach &&
    scarlatti &&
    isStudent &&
    recentTeacher;

  const paypalPaymentSubscription = () => {
    // send email to HKSNA treasurer and chair
    sendEmailforPaypalSignup();
  };

  const sendEmailforPaypalSignup = async () => {
    sendMail(replaceAmpersand(body2), subject, recipients);
    sendMail(confirmationBody, subject, email);
  };

  const handleBachSelect = (e) => setBach(e.target.value);

  const handleScarlattiSelect = (e) => setScarlatti(e.target.value);

  const handleStudentSelect = (e) => setIsStudent(e.target.value);

  const bachPieces = [
    "No. 2 in C minor, BWV 847 | N° 2 en do mineur, BWV 847",
    "No. 5 in D major, BWV 850 | N° 5 en ré majeur, BWV 850",
    "No. 6 in D minor, BWV 851 | N° 6 en ré mineur, BWV 851",
    "No. 9 in E major, BWV 854 | N° 9 en mi majeur, BWV 854",
    "No. 11 in F major, BWV 856 | N° 11 en fa majeur, BWV 856",
    "No. 16 in G minor, BWV 861 | N° 16 en sol mineur, BWV 861",
    "No. 21 in B-flat major, BWV 866 | N° 21 en si bémol majeur, BWV 866",
    "No. 23 in B major, BWV 868 | N° 23 en si majeur, BWV 868",
  ];

  const scarlattiPieces = [
    "D minor, K. 141 | Ré mineur, K. 141",
    "F-sharp minor, K. 142 | Fa dièse mineur, K. 142",
    "F minor, K. 187 | Fa mineur, K. 187",
    "G major, K. 235 | Sol majeur, K. 235",
    "G major, K. 433 | Sol majeur, K. 433",
    "F minor, K. 555 | Fa mineur, K. 555",
  ];

  return (
    <div className='competition-form__container'>
      <Box mb={1}>
        <Typography variant='h5' color='primary'>
          Application Form
        </Typography>
        <Typography variant='h6'>
          2024 Jurow International Harpsichord Competition | 2024 concours
          international de clavecin Jurow
        </Typography>
      </Box>
      {/* <hr /> */}
      {/* <div style={{ marginBottom: 10 }}>
        <Typography variant='h6' color='primary'>
          Register and Pay By Mail
        </Typography>
        <Typography variant='body1'>
          Please{" "}
          <a href={offlineFormUrl} target='_blank' download>
            {" "}
            click here to download the application form
          </a>
          , and send it together with your check, made payable to HKSNA, to:
          <br />
          <br />
          <span> Mark R. Kain</span>
          <br />
          <span>100 Magnolia Avenue </span>
          <br />
          <span>Selinsgrove, PA 17870</span>
        </Typography>
      </div> */}
      <hr />
      {!sent && (
        <>
          <Typography variant='h6' color='primary'>
            Register and Pay Online{" "}
          </Typography>
          <Typography color='primary'>
            To complete the application, you will need to:
          </Typography>
          <Typography color='primary'>
            Pour remplir votre candidature, vous avez besoin de:
            <br />
            <br />
            Send a photocopy of your passport or birth certificate, showing your
            name, date of birth, and nationality, to jurow@hksna.org.{" "}
          </Typography>
          <Typography>
            {" "}
            Envoyez une photocopie de votre passeport ou acte de naissance,
            indiquant votre nom, votre date de naissance et votre nationalité, à
            jurow@hksna.org.
          </Typography>
        </>
      )}

      {!sent && !loading && (
        <form className='competition-form'>
          <div className='input'>
            <TextField
              name='Email'
              className='competition-form__input'
              variant='outlined'
              label='Email'
              type='email'
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='competition-form__input'>
            <TextField
              name='First`-name'
              className='input'
              variant='outlined'
              label='Given name(s) | Prénom(s)'
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className='competition-form__input'>
            <TextField
              name='Last_name'
              className='competition-form__input'
              variant='outlined'
              label='Family name(s) | Nom(s) de famille'
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className='competition-form__input'>
            <TextField
              name='Last_name'
              className='competition-form__input'
              variant='outlined'
              label='Date of Birth | Date de naissance (MM/DD/YYYY)'
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
          <p className='competition-form__disclaimer'>
            <small>
              To be eligible,{" "}
              <b> candidates must be born on or after July 7, 1989 </b>
              (under 35 at the time of the competition) | Pour être éligible,
              <b>
                les candidats doivent être nés le 7 juillet 1989 ou aprés{" "}
              </b>{" "}
              (âgés de moins de 35 ans au moment du concours)
            </small>
          </p>

          <div className='input'>
            <TextField
              name='Nationality'
              className='competition-form__input'
              variant='outlined'
              label='Nationality | Nationalité'
              onChange={(e) => setNationality(e.target.value)}
            />
          </div>

          <TextInput
            text1=' Mailing address (number, street, and apartment number if applicable)'
            text2="Adresse de correspondance (numéro civique, rue et numéro
              d'appartement si applicable)"
            label='Address / Adrésse'
            name='Address1'
            onChange={(e) => setStreet1(e.target.value)}
            value={street1}
          />

          <div className='input'>
            <TextField
              name='City'
              className='competition-form__input'
              variant='outlined'
              label='City | Ville'
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          <div className='input'>
            <TextField
              name='State / Provice'
              className='competition-form__input'
              variant='outlined'
              label='State or Province | État ou Province'
              onChange={(e) => setProvince(e.target.value)}
            />
          </div>
          <div className='input'>
            <TextField
              name='Country'
              className='competition-form__input'
              variant='outlined'
              label='Country | Pays'
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <div className='input'>
            <TextField
              name='Postcode'
              className='competition-form__input'
              variant='outlined'
              label='Zip or Postal Code | Code Zip ou Code postal'
              onChange={(e) => setPostcode(e.target.value)}
            />
          </div>

          <TextInput
            text1=' Your folder link containing four required videos for the preliminary
              round'
            text2="Le lien vers votre dossier contenant les quatre vidéos obligatoires
              pour l'épreuve préliminaire"
            value={folderLink}
            onChange={(e) => setFolderLink(e.target.value)}
            label='Enter link here'
            name='Folder Link'
          />
          <div style={{ marginTop: 10 }}>
            <RadioGroup
              containerClassnames='competition-form__radio-group'
              inputClassnames='competition-form__radio'
              text1=' Your choice of Prelude and Fugue by Bach (Well-Tempered Clavier,
                Book One)'
              text2=' Votre choix de Prélude et fugue de Bach (Premier Livre du Clavier
                bien tempéré)'
              groupName='bach'
              items={bachPieces}
              onChange={handleBachSelect}
            />
            <RadioGroup
              containerClassnames='competition-form__radio-group'
              inputClassnames='competition-form__radio'
              text1='Your choice of Scarlatti sonata'
              text2='Votre choix de sonate de Scarlatti'
              groupName='scarlatti'
              items={scarlattiPieces}
              onChange={handleScarlattiSelect}
            />
            <RadioGroup
              containerClassnames='competition-form__radio-group'
              inputClassnames='competition-form__radio'
              text1='Are you currently a student?'
              text2='Êtes-vous étudiant(e) actuellement?'
              groupName='student'
              items={[
                { label: "Yes | Oui", value: "Yes" },
                { label: "No | Non", value: "No" },
              ]}
              onChange={handleStudentSelect}
            />
            <TextInput
              text1='Name(s) of your most recent harpsichord teacher(s)'
              text2='Nom(s) de vos plus récent(s) professeur(s) de clavecin'
              value={recentTeacher}
              onChange={(e) => setRecentTeacher(e.target.value)}
              label='Recent teacher(s) | récent(s) professeur(s)'
              name='Recent teachers'
            />
          </div>
          <hr />
          <ReCaptcha onSuccess={() => setRecaptcha(true)} />
          <hr />
          <div style={{ marginBottom: 8 }}>
            <Typography color='primary' variant='h5'>
              Payment Options:{" "}
            </Typography>

            <hr />
            <Typography>
              Payment methods will appear upon completion of the above form.
            </Typography>
          </div>
          {/* <button
            type='button'
            disabled={!readyToSend()}
            onClick={paypalPaymentSubscription}
          >
            TEST
          </button> */}
          {readyToSend() && (
            <div>
              {/* <Button
                  style={{ marginTop: 15 }}
                  onClick={checkPaymentSubscription}
                  variant='contained'
                  color='primary'
                  disabled={!readyToSend()}
                >
                  Register Now, then pay by check
                </Button> */}
              <hr />
              <div>
                <hr />
                <Typography variant='h6' color='primary'>
                  Pay By Paypal
                </Typography>
                please use the button below to complete the process. Upon
                successful payment, your registration will be sent to us.
              </div>
              <div
                style={
                  !readyToSend() ? { pointerEvents: "none", opacity: 0.5 } : {}
                }
              >
                <PayPalButton
                  price={150}
                  description={`Jurow Competition Application: $150`}
                  clientId={paypalClientId}
                  currency='USD'
                  paySubmit={paypalPaymentSubscription}
                />
              </div>
            </div>
          )}
        </form>
      )}
      {loading && <Spinner />}
      {sent && (
        <div style={{ textAlign: "center" }}>
          <Typography
            variant='h6'
            color='primary'
            style={{ textAlign: "center" }}
          >
            Thank you for your application.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CompetitionApplicationForm;
