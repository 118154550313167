import React, { useEffect } from "react";
import "./404PageStyles.scss";
import { Link } from "react-router-dom";

import { Typography, Button } from "@material-ui/core";

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='container'>
      <div className=' notfound-container'>
        <Typography variant='h4'>Page Not Found!</Typography>
        <Typography variant='body1'>
          The page you have tried to access either does not exist, or required
          user-login. Please check the address, or use the button to log in.
        </Typography>
        <div style={{ marginTop: 35 }}>
          <Link to='/membership'>
            <Button variant='outlined'>Log in</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
