import React, { useState, useEffect } from "react";
import { getScholarships } from "../../contentful";
import { Typography } from "@material-ui/core";
import "./ScholarshipsStyles.scss";
import { Link } from "react-router-dom";
import moment from "moment";

import PageTitle from "../../components/PageTitle";
import Helmet from "../../components/Helmet";
import ConferenceIntroductionCard from "../../components/ConferenceIntroductionCard";
import ScholarshipCard from "./ScholarshipCard";

const ConferencesPage = () => {
  const [conferences, setConferences] = useState([]);
  const today = new Date();

  useEffect(() => {
    window.scrollTo(0, 0);
    getScholarships().then((data) => setConferences(data));
  }, []);
  return (
    <div className='container'>
      <Helmet title={`HKSNA | Scholarships `} />

      <PageTitle title='Scholarships' />

      {conferences.map((s) => (
        <ScholarshipCard scholarship={s} />
      ))}
    </div>
  );
};

export default ConferencesPage;
