import React, { useState, useEffect } from "react";
import "./MembershipPageStyles.scss";
import { useSelector, useDispatch } from "react-redux";
import PayPalButton from "react-paypal-smart-button";
import { Link } from "react-router-dom";

import {
  Tabs,
  Tab,
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

import useFirebase from "../../hooks/useFirebase";
import Helmet from "../../components/Helmet";
import MyProfile from "../../components/MyProfile/MyProfile";
import Spinner from "../../components/Spinner";
import UsersPane from "../../components/MembersDirectoryPane";
import PageTitle from "../../components/PageTitle";
import ContactForm from "../../components/ContactForm";
import MembershipForm from "../../components/MembershipForm";
const MembershipPage = () => {
  const dispatch = useDispatch();
  const {
    createUser,
    login,
    createSubscription,
    renewSubscription,
    googleSignIn,
    fetchUsers,
  } = useFirebase();
  const [selectedTab, setSelectedTab] = useState();

  const {
    user,
    paidUser,
    loading,
    accountExpired,
    paypalClientId,
    loginArea,
  } = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState(null);
  const [usState, setUSState] = useState("");
  const [mainInstruments, setMainInstruments] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabClick = (e, tab) => {
    setSelectedTab(tab);
  };

  return (
    <div style={{ marginTop: 20 }} className='container'>
      {!user && (
        <>
          <PageTitle title='Membership' />
          {loginArea && (
            <div
              style={{
                margin: "5%",
                border: "1px solid #ccc ",
                borderRadius: 20,
                padding: 20,
                textAlign: "center",
              }}
            >
              <Typography>
                HKSNA membership allows you to log in and access members-only
                content on this website.
              </Typography>
              <Typography variant='body1'>This will include:</Typography>
              <ul style={{ listStyle: "none" }}>
                <li>
                  {" "}
                  <Typography> Directory of all members?</Typography>
                </li>
                <li>
                  {" "}
                  <Typography> members-only news?</Typography>
                </li>
                <li>
                  {" "}
                  <Typography> members-only events?</Typography>
                </li>
              </ul>
              <Typography>
                First, register yourself on the system for free (which
                automatically adds you to our mailing list), and then select
                your membership plan. Regular membership costs $50, with a 50%
                discount for Associate members and students.
              </Typography>
              <Box mt={3}>
                <Link style={{ textDecoration: "none" }} to='/login'>
                  <Button variant='contained'>To Login Page</Button>
                </Link>
              </Box>
            </div>
          )}

          {!loginArea && (
            <div
              style={{
                margin: "5%",
                border: "1px solid #ccc ",
                borderRadius: 20,
                padding: 20,
                textAlign: "center",
              }}
            >
              <MembershipForm />
              {/* <ContactForm /> */}
            </div>
          )}
        </>
      )}
      {user && (
        <Typography variant='h4' color='primary'>
          Membership Management
        </Typography>
      )}
      <Helmet title={`HKSNA | Membership `} />
      {loading && (
        <p>
          <Spinner />{" "}
        </p>
      )}

      {user && (
        <div>
          <Typography variant='h6'>Welcome to HKSNA, {user.name}</Typography>
          {user && !paidUser && (
            <div>
              <Typography>
                You are registered on our system and will receive mailing list
                email from time to time. Please continue your full membership
                subscription by visiting our login page:{" "}
              </Typography>
              <Button style={{ marginTop: 16 }} variant='contained'>
                <Link style={{ textDecoration: "none" }} to='/login'>
                  To Login Page
                </Link>
              </Button>
            </div>
          )}
          {paidUser && !loading && !accountExpired && (
            <>
              <Typography variant='body1'>
                Thankyou for subscribing, {paidUser.name}. You now have access
                to members-only content
              </Typography>
              <Box mt={1}>
                <Typography variant='body1'>This will include:</Typography>
                <ul>
                  <li>
                    {" "}
                    <Typography> Directory of all members?</Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography> members-only news?</Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography> members-only events?</Typography>
                  </li>
                </ul>
                <Tabs
                  indicatoryColor='primary'
                  value={selectedTab}
                  onChange={handleTabClick}
                >
                  <Tab label={<span>My Profile</span>} />
                  <Tab label={<span>Members' Directory</span>} />
                </Tabs>
                {selectedTab === 0 && <MyProfile />}
                {selectedTab === 1 && <UsersPane />}
              </Box>
            </>
          )}{" "}
          {paidUser && accountExpired && (
            <>
              <p>
                It seems that your subscription has expired, please click here
                to renew. You'll keep all your account details and will be
                granted another year of access to our members-only content.
              </p>
              <Box style={{ width: "50%", margin: "auto", marginTop: 10 }}>
                <PayPalButton
                  price={1}
                  description='1 Year, HKSNA Membership Fee'
                  clientId={paypalClientId}
                  currency='USD'
                  paySubmit={renewSubscription}
                />
              </Box>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default MembershipPage;
