import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyB3dBv5lCjapSY1jEcv5uzTD4R9nqVxRY4",
  authDomain: "hksna-56672.firebaseapp.com",
  projectId: "hksna-56672",
  storageBucket: "hksna-56672.appspot.com",
  messagingSenderId: "393800526822",
  appId: "1:393800526822:web:b61f0700d7870ef0200427",
  measurementId: "G-FTPRTBXG2F",
};
firebase.initializeApp(firebaseConfig);

export default firebase;
