import React from "react";
import Recaptcha from "react-recaptcha";

const ReCaptcha = ({ onSuccess }) => {
  const reCaptchaLoaded = () => {
    console.log("recaptcha loaded");
  };

  const verifyCallback = (response) => {
    if (response) onSuccess();
  };

  return (
    <Recaptcha
      sitekey='6LeCjSwdAAAAAEHK9aAIuLYbzvWBMm2LY6mw02td'
      render='explicit'
      onloadCallback={reCaptchaLoaded}
      verifyCallback={verifyCallback}
    />
  );
};
export default ReCaptcha;
