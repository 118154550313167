import React from "react";
import "./PageTitleStyles.scss";

import { Typography } from "@material-ui/core";

const PageTitle = (props) => {
  return (
    <div className='page-title-container'>
      <div>
        <Typography variant='h4' color='primary' className='page-title'>
          {props.title}
        </Typography>
        <Typography variant='h6' color='secondary'>
          {props.subtitle}
        </Typography>
      </div>
    </div>
  );
};

export default PageTitle;
