import React from "react";
import { Link } from "react-router-dom";
import "./ScholarshipsStyles.scss";
import { Typography } from "@material-ui/core";
const ScholarshipCard = ({ scholarship }) => {
  const { navigationLink, title, intro } = scholarship?.fields || {};
  console.log(scholarship);
  return (
    <div className='scholarship-card-container'>
      <Typography
        className='scholarship-card__title'
        variant='h5'
        color='primary'
      >
        {title}
      </Typography>
      <Typography className='scholarship-card__intro'>{intro}</Typography>

      {navigationLink && (
        <Link
          style={{ textDecoration: "none" }}
          to={`/scholarships/${navigationLink}`}
        >
          <Typography color='primary'>More information</Typography>
        </Link>
      )}
    </div>
  );
};

export default ScholarshipCard;
