import React, { useEffect, useState } from "react";
import { getJournalIndexItems } from "../../contentful";
import PageTitle from "../../components/PageTitle";
import Tabs from "../../components/Tabs/Tabs";
import { Typography } from "@material-ui/core";

const Journals = () => {
  const [journals, setJournals] = useState([]);
  const [normalisedJournals, setNormalisedJournals] = useState([]);

  useEffect(() => {
    getJournalIndexItems().then((data) => setJournals(data));
  }, []);

  useEffect(() => {
    if (journals) {
      const grouped = journals.reduce((acc, item) => {
        if (!acc.map((i) => i.year).includes(item.fields.year)) {
          acc.push({
            year: item.fields.year,
            journals: [
              {
                title: item.fields.title,
                content: item.fields.content,
                position: item.fields.position,
              },
            ],
          });
          return acc;
        } else {
          acc
            .find((i) => i.year === item.fields.year)
            .journals.push({
              title: item.fields.title,
              content: item.fields.content,
              position: item.fields.position,
            });
          acc
            .find((i) => i.year === item.fields.year)
            .journals.sort((a, b) => a.position - b.position);
          return acc;
        }
      }, []);

      setNormalisedJournals(
        grouped.sort((a, b) => parseInt(a.year) - parseInt(b.year)).reverse()
      );
    }
  }, [journals]);

  return (
    <div className='container'>
      <PageTitle title='Journal Index' />

      {normalisedJournals?.map((group) => (
        <div style={{ marginBottom: 40 }}>
          <Typography variant='h5' color='primary'>
            {group.year}
          </Typography>
          <Tabs items={group.journals} />
        </div>
      ))}
    </div>
  );
};

export default Journals;
