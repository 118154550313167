import React, { useState, useEffect, useRef } from "react";
import "./AccordionStyles.scss";
import { Typography } from "@material-ui/core";
import marked from "marked";
import { Add, Close } from "@material-ui/icons";

const Accordion = ({ header, content, mainSection, defaultOpen }) => {
  const [open, setOpen] = useState(defaultOpen ? true : false);
  const [contentHeight, setContentHeight] = useState(0);
  const ref = useRef();

  useEffect(() => setContentHeight(open ? ref.current.scrollHeight : 0));

  return (
    <div>
      <hr />
      {mainSection ? (
        <>
          {" "}
          <div onClick={() => setOpen(!open)} className='accordion-header'>
            <Typography color='primary' variant='h5'>
              {header}
            </Typography>

            <div
              className='accordion-header--toggle-button'
              onClick={() => setOpen(!open)}
            >
              {" "}
              {open ? <Close color='primary' /> : <Add color='primary' />}
            </div>
          </div>
          <hr />
        </>
      ) : (
        <div onClick={() => setOpen(!open)} className='accordion-header--dark'>
          {" "}
          <Typography variant='h6'>{header}</Typography>
          <div className='accordion-header--toggle-button'>
            {" "}
            {open ? <Close /> : <Add />}
          </div>
        </div>
      )}
      {content && (
        <div
          ref={ref}
          className={`accordion-content ${open ? "accordion-open" : ""}`}
          style={{ maxHeight: contentHeight }}
        >
          <Typography
            dangerouslySetInnerHTML={{
              __html: marked(content),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Accordion;
