export const ACTIONTYPES = {
  TOGGLEDRAWER: "toggle-drawer",
  TOGGLE_CONTACT_MODAL: "toggle-contact-modal",
  TOGGLE_SUBMISSIONS_MODAL: "toggle-submissions-modal",
  TOGGLE_LOADING: "toggle-loading",
  SET_USER: "set-user",
  SET_PAID_USER: "set-paid-user",
  LOGOUT_PAID_USER: "logout-paid-user",
  SET_LOADING: "set-loading",
  SET_ERROR_MESSAGE: "set-error-message",
  SET_SUCCESS_MESSAGE: "set-success-message",
  ACCOUNT_EXPIRED: "account-expired",
  TOGGLE_CONFERENCE_REGISTRATION_MODAL: "toggle-conference-registration-modal",
  TOGGLE_DONATIONS_MODAL: "toggle-donations-modal",
  TOGGLE_GDPR_MODAL: "toggle-gdpr-modal",
  SET_MEMBERS: "set-users",
};
