import React from "react";
import { Helmet } from "react-helmet";

const HelmetMeta = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title || "HKSNA"}</title>
      <meta
        name='description'
        content={
          description || "Historical Keyboard Association of North America"
        }
      />
    </Helmet>
  );
};

export default HelmetMeta;
