import React, { useState, useEffect } from "react";
import "./ProposalsModalStyles.scss";
import { useSelector, useDispatch } from "react-redux";

import { ACTIONTYPES } from "../../store/ActionTypes";
import { Dialog, Box, TextField, Button, Typography } from "@material-ui/core";
import Spinner from "../../components/Spinner/Spinner";
import useSendEmail from "../../hooks/useSendEmail";
import ReCaptcha from "../ReCaptcha/ReCaptcha";
import { getContactDestinations } from "../../contentful";

const ProposalsModal = () => {
  const dispatch = useDispatch();
  const { sendMail, sent } = useSendEmail();

  const { submissionModal, selectedConference, loading } = useSelector(
    (state) => state
  );
  const [userEnteredConferenceTitle, setUserEnteredConferenceTitle] = useState(
    ""
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [abstract, setAbstract] = useState("");
  const [recipients, setRecipients] = useState("secretary@hksna.org");
  const [recaptcha, setRecaptcha] = useState(false);

  let abstractLength = abstract.split(" ").length;
  const maxLength = 300;
  let abstractOverflow = abstractLength - maxLength;

  useEffect(() => {
    getContactDestinations().then((data) => {
      const contactForm = data.find(
        (data) => data.fields.place === "Proposals Submissions"
      );
      if (contactForm) setRecipients(contactForm?.fields.email?.join(", "));
    });
  }, []);

  const subject = `Proposal submission from ${firstName} ${lastName} `;

  const body = `<div><h4>Conference: ${
    selectedConference && selectedConference.title
  }${userEnteredConferenceTitle}</h4><h5>Name: ${firstName} ${lastName}</h5><p>Email: ${email}</p><p>Institution: ${affiliation}</p><h5>Abstract</h5><p>${abstract}</p></div>`;

  const onSubmit = (e) => {
    sendMail(body, subject, recipients);
  };

  const formValid = () => {
    if (
      recaptcha &&
      firstName &&
      lastName &&
      email &&
      abstract &&
      abstractLength < maxLength
    )
      return true;
  };
  return (
    <Dialog
      open={submissionModal}
      onClose={() => dispatch({ type: ACTIONTYPES.TOGGLE_SUBMISSIONS_MODAL })}
    >
      <div className='proposals-modal'>
        <Box mb={1}>
          <Typography variant='h5' color='primary'>
            Proposal Submission Form
          </Typography>
          {selectedConference && (
            <Typography variant='h6'>
              Conference: {selectedConference.title}
            </Typography>
          )}
        </Box>
        {!sent && !loading && (
          <form className='proposals-form'>
            {!selectedConference && (
              <div className='input'>
                <TextField
                  name='Event'
                  className='input'
                  variant='outlined'
                  label='Conference date / title'
                  onChange={(e) =>
                    setUserEnteredConferenceTitle(e.target.value)
                  }
                />
              </div>
            )}
            <div className='input'>
              <TextField
                name='First_name'
                className='input'
                variant='outlined'
                label='First name'
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='input'>
              <TextField
                name='Last_name'
                className='input'
                variant='outlined'
                label='Last name'
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className='input'>
              <TextField
                name='Email'
                type='email'
                className='input'
                variant='outlined'
                label='Email Address'
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='input'>
              <TextField
                name='Affiliation'
                className='input'
                variant='outlined'
                label='Affiliation'
                onChange={(e) => setAffiliation(e.target.value)}
              />
            </div>
            {abstractLength > maxLength && (
              <Typography
                variant='subtitle2'
                style={{ color: "red", marginBottom: 8 }}
              >
                You have exceeded the 300 word limit by {abstractOverflow} word
                {abstractOverflow > 1 && <span>s</span>}.
              </Typography>
            )}
            <TextField
              name='Abstract'
              className='input'
              variant='outlined'
              multiline
              rows={20}
              label='Abstract (limit 300 words)'
              onChange={(e) => setAbstract(e.target.value)}
            />
            <div
              style={{
                marginTop: 8,
              }}
            >
              <ReCaptcha onSuccess={() => setRecaptcha(true)} />
            </div>
            <div>
              <Button
                disabled={!formValid()}
                onClick={onSubmit}
                color='primary'
                type='button'
                variant='contained'
              >
                Submit
              </Button>
              <Button
                variant='outlined'
                onClick={() =>
                  dispatch({ type: ACTIONTYPES.TOGGLE_SUBMISSIONS_MODAL })
                }
              >
                cancel
              </Button>
            </div>
          </form>
        )}
        {loading && <Spinner />}
        {sent && (
          <div style={{ textAlign: "center" }}>
            <Typography
              variant='h6'
              color='primary'
              style={{ textAlign: "center" }}
            >
              Thank you for your submission. We will respond as soon as
              possible.
            </Typography>

            <div style={{ marginTop: 35 }}>
              <Button
                onClick={() =>
                  dispatch({ type: ACTIONTYPES.TOGGLE_SUBMISSIONS_MODAL })
                }
                variant='outlined'
                color='primary'
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ProposalsModal;
