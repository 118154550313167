import React from "react";
import "./FooterStyles.scss";
import { useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";
import { Link } from "react-router-dom";
import SharingIcons from "../SharingIcons/SharingIcons";

import { Typography } from "@material-ui/core";

const Footer = () => {
  const dispatch = useDispatch();
  return (
    <div className='footer-container'>
      <div>
        <Typography variant='body1'>
          Designed and built by{" "}
          <a href='https://webspinner.eu'>WebSpinner.eu</a>
        </Typography>
      </div>
      <div>
        <SharingIcons />

        <Typography
          className='footer-container__modal-toggle'
          onClick={() => dispatch({ type: ACTIONTYPES.TOGGLE_GDPR_MODAL })}
        >
          HKSNA Privacy Policy
        </Typography>
        <Link to='/admin'>
          <Typography>Admin</Typography>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
