import { useEffect } from "react";
import firebase from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ACTIONTYPES } from "../store/ActionTypes";
import moment from "moment";

const useFirebase = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, paidUser } = useSelector((state) => state);

  useEffect(() => {
    authListener();
  }, []);

  const authListener = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .storage()
          .ref(`members/${user.uid}/avatars/avatar.jpg`)
          .getDownloadURL()
          .then((imgUrl) => {
            firebase.auth().currentUser.updateProfile({ photoURL: imgUrl });
          });
        dispatch({
          type: ACTIONTYPES.SET_USER,
          payload: {
            email: user.email,
            name: user.displayName,
            id: user.uid,
            avatar: user.photoURL,
          },
        });
        dispatch({
          type: ACTIONTYPES.SET_SUCCESS_MESSAGE,
          payload: "Login successful",
        });
        //Get all users for directory and to find current.

        firebase
          .database()
          .ref("paid-members")
          .on("value", (snapshot) => {
            const users = snapshot.val();

            let keys = [];
            if (users) {
              keys = Object.keys(users);
            }

            let array = [];

            for (let i = 0; i < keys.length; i++) {
              let k = keys[i];
              let name = users[k].name;
              let email = users[k].email;
              let createdAt = users[k].createdAt;
              let city = users[k].city;
              let state = users[k].state;
              let instruments = users[k].instruments;
              let address1 = users[k].address1;
              let address2 = users[k].address2;
              let avatar = users[k].avatar;
              let student = users[k].student;
              let teacher = users[k].teacher;
              let maker = users[k].maker;
              let biog = users[k].biog;

              array.push({
                firebaseKey: k,
                name,
                email,
                address1,
                address2,
                city,
                state,
                avatar,
                instruments,
                createdAt,
                student,
                teacher,
                maker,
                biog,
              });
            }

            // Check if logged in user has HKSNA profile, if so, set paidUser account.

            if (array.map((user) => user.email).includes(user.email)) {
              const currentSubscription = array.find(
                (x) => x.email === user.email
              );
              dispatch({
                type: ACTIONTYPES.SET_PAID_USER,
                payload: currentSubscription,
              });

              // check subscription age.  If more than 1 year, set as expired and request payment again.
              const now = moment(new Date());
              const accountCreationDate = moment(currentSubscription.createdAt);
              const oneYear = 525600;
              const timeLapsedInSeconds =
                now.diff(accountCreationDate) / 1000 / 60;
              // console.log(timeLapsedInSeconds);
              timeLapsedInSeconds > oneYear
                ? dispatch({ type: ACTIONTYPES.ACCOUNT_EXPIRED, payload: true })
                : dispatch({
                    type: ACTIONTYPES.ACCOUNT_EXPIRED,
                    payload: false,
                  });
              // history.push("/membership");
            } else {
              console.log("user has no HKSNA profile, yet....");
              // history.push("/login");
            }
          });
      }
    });
  };

  // Create firebase auth user
  const createUser = (email, password, name) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        auth.user.updateProfile({ displayName: name });
        dispatch({
          type: ACTIONTYPES.SET_SUCCESS_MESSAGE,
          payload: "Signup successful",
        });
      })
      .catch((error) => console.log(error));
  };

  const updateProfile = (user, key) => {
    console.log(user);
    console.log(key);
    firebase.database().ref(`paid-members/${key}`).set(user);
  };

  // login
  const login = (email, password) => {
    dispatch({
      type: ACTIONTYPES.TOGGLE_LOADING,
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        // console.log(user.user.photoURL);
        dispatch({
          type: ACTIONTYPES.SET_USER,
          payload: {
            name: user.user.displayName,
            email: user.user.email,
            avatar: user.user.photoURL,
          },
        });

        dispatch({
          type: ACTIONTYPES.SET_SUCCESS_MESSAGE,
          payload: "Login successful",
        });
        dispatch({
          type: ACTIONTYPES.TOGGLE_LOADING,
        });
        history.push("/");
      })
      .catch((error) => {
        let errorMessage = error.message;
        dispatch({
          type: ACTIONTYPES.SET_ERROR_MESSAGE,
          payload: "An error has occurred",
        });
        // console.log(errorMessage)
      });
  };

  // create HKSNA user account

  const createSubscription = (subscription) => {
    firebase
      .database()
      .ref(`paid-members`)
      .push({
        name: user.name || subscription.name,
        email: user.email || subscription.name,
        avatar: subscription.avatar,
        institute: subscription.institute,
        address1: subscription.address1,
        address2: subscription.address2,
        city: subscription.city,
        state: subscription.province,
        zip: subscription.zip,
        country: subscription.country,
        instruments: subscription.mainInstruments,
        studentDiscount: subscription.studentDiscount,
        id: user.id,
        createdAt: moment().format(),
        isMaker: subscription.isMaker,
        isTeacher: subscription.isTeacher,
        biog: subscription.biog,
        membershipType: subscription.membershipType,
      });
  };

  const renewSubscription = () => {
    firebase
      .database()
      .ref(`paid-members/${paidUser.k}`)
      .set({ ...paidUser, createdAt: moment().format() });

    // firebase.database().ref(`paid-members`).push({
    //   name: user.name,
    //   email: user.email,
    //   city,
    //   state,
    //   instruments,
    //   id: user.id,
    //   createdAt: moment().format(),
    // });
    // console.log(paidUser);
  };

  // logout

  const logout = () => {
    firebase.auth().signOut();
    dispatch({ type: ACTIONTYPES.SET_USER, payload: null });
    dispatch({ type: ACTIONTYPES.LOGOUT_PAID_USER });
    dispatch({
      type: ACTIONTYPES.SET_SUCCESS_MESSAGE,
      payload: "logged out",
    });
    history.push("/");
  };
  const provider = new firebase.auth.GoogleAuthProvider();

  const googleSignIn = () => {
    dispatch({
      type: ACTIONTYPES.TOGGLE_LOADING,
    });
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // console.log(user);
        // ...
        dispatch({
          type: ACTIONTYPES.SET_USER,
          payload: { email: user.email, name: user.displayName, id: user.uid },
        });
        dispatch({
          type: ACTIONTYPES.TOGGLE_LOADING,
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTIONTYPES.TOGGLE_LOADING,
        });
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const fetchUsers = () => {
    firebase
      .database()
      .ref("paid-members")
      .on("value", (snapshot) => {
        const users = snapshot.val();
        let keys = [];
        if (users) {
          keys = Object.keys(users);
        }

        let array = [];

        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          let name = users[k].name;
          let email = users[k].email;
          let createdAt = users[k].createdAt;
          let city = users[k].city;
          let state = users[k].state;
          let instruments = users[k].instruments;
          let address1 = users[k].address1;
          let address2 = users[k].address2;
          let avatar = users[k].avatar;
          let student = users[k].student;
          let teacher = users[k].teacher;
          let maker = users[k].maker;
          let biog = users[k].biog;

          array.push({
            firebaseKey: k,
            name,
            email,
            address1,
            address2,
            city,
            state,
            avatar,
            instruments,
            createdAt,
            student,
            teacher,
            maker,
            biog,
          });
        }
        // console.log(array);
        dispatch({ type: ACTIONTYPES.SET_MEMBERS, payload: array });
      });
  };

  const removeMember = (m) => {
    // console.log(m);
  };

  const changePassword = (email) => {
    const config = {
      url: "https://hksna.org/login",
      handleCodeInApp: true,
    };
    firebase
      .auth()
      .sendPasswordResetEmail(email, config)
      .then((result) => {
        // console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    googleSignIn,
    createUser,
    login,
    logout,
    createSubscription,
    renewSubscription,
    updateProfile,
    authListener,
    fetchUsers,
    removeMember,
    changePassword,
  };
};

export default useFirebase;
