import React from "react";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import { Typography } from "@material-ui/core";

const SharingIcons = () => {
  return (
    <div className='sharing-container'>
      <Typography variant='subtitle2'>
        Share this page on your social media
      </Typography>
      <div className='sharing-buttons-container'>
        <br></br>
        <TwitterShareButton
          url={window.location}
          children={<TwitterIcon size={24} />}
        />
        <LinkedinShareButton
          url={window.location}
          children={<LinkedinIcon size={24} />}
        />
        <FacebookShareButton
          url={window.location}
          children={<FacebookIcon size={24} />}
        />
      </div>{" "}
    </div>
  );
};

export default SharingIcons;
