import React, { useState, useEffect } from "react";
import PayPalButton from "react-paypal-smart-button";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";
import firebase from "../../firebase";
import { useHistory, Link } from "react-router-dom";
import useSendEmail from "../../hooks/useSendEmail";
import Spinner from "../Spinner";

import {
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from "@material-ui/core";
import useFirebase from "../../hooks/useFirebase";
import { getContactDestinations } from "../../contentful";

const MEMBERSHIP_TYPES = [
  { label: "Regular", fee: 50 },
  { label: "Institutional", fee: 50 },
  { label: "Associate (spouse or partner of a regular member)", fee: 25 },
  { label: "Student", fee: 25 },
];

const MembershipForm = ({ admin, addSubscription }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sendMail, sent } = useSendEmail();

  const {
    createUser,
    login,
    createSubscription,
    renewSubscription,
    googleSignIn,
  } = useFirebase();
  const {
    user,
    paidUser,
    loading,
    accountExpired,
    paypalClientId,
  } = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState(null);
  const [province, setProvince] = useState("");
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [zip, setZip] = useState(null);
  const [country, setCountry] = useState(null);
  const [institute, setInstitute] = useState(null);
  const [biog, setBiog] = useState("");
  const [mainInstruments, setMainInstruments] = useState(null);
  const [studentDiscount, setStudentDiscount] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isMaker, setIsMaker] = useState(false);
  const [isRenewal, setIsRenewal] = useState(false);
  const [isNewMember, setIsNewMember] = useState(false);
  const [isPerformer, setIsPerformer] = useState(false);
  const [isResearcher, setIsResearcher] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [fee, setFee] = useState(0);
  const [membershipType, setMembershipType] = useState("");
  const [offlineFormUrl, setOfflineFormUrl] = useState("");

  const [recipients, setRecipients] = useState("secretary@hksna.org");
  useEffect(() => {
    getContactDestinations().then((data) => {
      const contactForm = data.find(
        (data) => data.fields.place === "Membership Form"
      );
      if (contactForm) {
        setRecipients(contactForm?.fields.email?.join(", "));
        contactForm?.fields.form &&
          setOfflineFormUrl(contactForm?.fields.form?.fields.file.url);
      }
    });
  }, []);

  // FOR EMAIL SENT TO HKSNA on Pay By Check
  const subject = `New Member Subscription from ${email} `;
  const subject2 = `Thankyou for your membership subscription.`;

  const body = `<div><h4>New member subscription from ${name}</h4><p>${email}, stating that they will pay by check the amount of $${fee}</p><h4>Details</h4><p>Name: ${name}</p><p>Email: ${email}</p><p>Address 1: ${address1}</p><p>Address 2: ${address2}</p><p>City: ${city}</p><p>Province: ${province}</p><p>Zip: ${zip}</p><p>Country: ${country}</p><p>Institute: ${institute}</p><p>Main Instruments: ${mainInstruments}</p><p>Student?: ${studentDiscount}</p><p>New Member?: ${isNewMember}</p><p>Renweal?: ${isRenewal}</p><p>Performer?: ${isPerformer}</p><p>Teacher?: ${isTeacher}</p><p>Maker?: ${isMaker}</p><p>Researcher?: ${isResearcher}</p><p>Fee paid: ${fee}</p><p>Membership Type: ${membershipType}</p><p>Biog: ${biog}</p></div>`;
  const body2 = `<div><h4>New member subscription from ${name}</h4><p>${email}, who has paid the amount of $${fee} via paypal</p><h4>Details</h4><p>Name: ${name}</p><p>Email: ${email}</p><p>Address 1: ${address1}</p><p>Address 2: ${address2}</p><p>City: ${city}</p><p>Province: ${province}</p><p>Zip: ${zip}</p><p>Country: ${country}</p><p>Institute: ${institute}</p><p>Main Instruments: ${mainInstruments}</p><p>Student?: ${studentDiscount}</p><p>New Member?: ${isNewMember}</p><p>Renweal?: ${isRenewal}</p><p>Performer?: ${isPerformer}</p><p>Teacher?: ${isTeacher}</p><p>Maker?: ${isMaker}</p><p>Researcher?: ${isResearcher}</p><p>Fee paid: ${fee}</p><p>Membership Type: ${membershipType}</p><p>Biog: ${biog}</p></div>`;
  const confirmationBody = `<div><p>Dear ${name}</p><p>Many thanks for your subscription and agreed payment of $${fee}.  If you paid via Paypal, you should receive an email from Paypal to confirm your payment was recieved.</p><p>we have registered the following details for you:</p><p>Name: ${name}</p><p>Email: ${email}</p><p>Address 1: ${address1}</p><p>Address 2: ${address2}</p><p>City: ${city}</p><p>Province: ${province}</p><p>Zip: ${zip}</p><p>Country: ${country}</p><p>Institute: ${institute}</p><p>Main Instruments: ${mainInstruments}</p><p>Student?: ${studentDiscount}</p><p>New Member?: ${isNewMember}</p><p>Renweal?: ${isRenewal}</p><p>Performer?: ${isPerformer}</p><p>Teacher?: ${isTeacher}</p><p>Maker?: ${isMaker}</p><p>Researcher?: ${isResearcher}</p><p>Fee paid: ${fee}</p><p>Membership Type: ${membershipType}</p><p>Biog: ${biog}</p><p>Should you have any queries, please reach out to use via email at secretary@hksna.org</p></div>`;

  useEffect(() => {
    user && setName(user.name);
    user && setEmail(user.email);
    user && console.log("LOGIN PAGE USER", user.name);
  }, []);

  const newSubscription = {
    name,
    email,
    institute,
    address1,
    address2,
    city,
    province,
    zip,
    country,
    mainInstruments,
    studentDiscount,
    isTeacher,
    isMaker,
    biog,
    avatar,
    fee,
    membershipType,
  };

  const sendEmailforCheckSignup = async () => {
    sendMail(body, subject, recipients);
    sendMail(confirmationBody, subject2, email);
  };

  const sendEmailforPaypalSignup = async () => {
    sendMail(body2, subject, recipients);
    sendMail(confirmationBody, subject2, email);
  };

  const handleAvatarFile = async (e) => {
    const file = e.target.files[0];
    // console.log(file);
    // var filesize = (file.size / 1024 / 1024).toFixed(2); // MB
    const storageRef = firebase.storage().ref(`members/${user.uid}/avatars`);
    const fileRef = storageRef.child("avatar.jpg");
    await fileRef.put(file);
    const newAvatar = await fileRef.getDownloadURL();
    setAvatar(newAvatar);
    firebase
      .auth()
      .currentUser.updateProfile({ photoURL: await fileRef.getDownloadURL() });

    dispatch({
      type: ACTIONTYPES.SET_USER,
      payload: { ...user, avatar: newAvatar },
    });
  };

  const detailsValid = () => name && email;

  const setMembership = (type) => {
    setFee(type.fee);
    setMembershipType(type.label);
  };

  const checkPaymentSubscription = () => {
    // Activate this once we have agreed what member details we will collect.
    // createSubscription(subscription);
    // send email to HKSNA secretary
    sendEmailforCheckSignup();
  };

  const paypalPaymentSubscription = () => {
    // Activate this once we have agreed what member details we will collect.
    // createSubscription(subscription);
    // send email to HKSNA secretary
    sendEmailforPaypalSignup();
  };

  const addToDatabase = () => {
    addSubscription(newSubscription);
  };

  return (
    <div>
      {!sent && (
        <div className='profile-registration-form'>
          {!user && (
            <>
              <hr />
              <Typography variant='h6' color='primary'>
                Register and Pay Online{" "}
              </Typography>
              <div className='input-container'>
                <TextField
                  onChange={(e) => setName(e.target.value)}
                  variant='outlined'
                  color='primary'
                  label='Name (required)'
                  type='text'
                  value={name}
                  fullWidth
                />
              </div>
              <div className='input-container'>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  variant='outlined'
                  color='primary'
                  label='Email (required)'
                  type='email'
                  value={email}
                  fullWidth
                />
              </div>
            </>
          )}
          <div className='input-container'>
            <TextField
              onChange={(e) => setAddress1(e.target.value)}
              variant='outlined'
              color='primary'
              label='Street Address 1'
              type='text'
              value={address1}
              fullWidth
            />
          </div>
          <div className='input-container'>
            <TextField
              onChange={(e) => setAddress2(e.target.value)}
              variant='outlined'
              color='primary'
              label='Street Address 2'
              type='text'
              value={address2}
              fullWidth
            />
          </div>
          <div className='input-container'>
            <TextField
              onChange={(e) => setCity(e.target.value)}
              variant='outlined'
              color='primary'
              label='City'
              type='text'
              value={city}
              fullWidth
            />
          </div>

          <div className='input-container'>
            <TextField
              onChange={(e) => setProvince(e.target.value)}
              variant='outlined'
              color='primary'
              label='State / Province'
              type='text'
              value={province}
              fullWidth
            />
          </div>
          <div className='input-container'>
            <TextField
              onChange={(e) => setZip(e.target.value)}
              variant='outlined'
              color='primary'
              label='Zip/Postal Code'
              type='text'
              value={zip}
              fullWidth
            />
          </div>
          <div className='input-container'>
            <TextField
              onChange={(e) => setCountry(e.target.value)}
              variant='outlined'
              color='primary'
              label='Country'
              type='text'
              value={country}
              fullWidth
            />
          </div>
          <div className='input-container'>
            <TextField
              onChange={(e) => setMainInstruments(e.target.value)}
              variant='outlined'
              color='primary'
              label='Main Instrument(s)'
              type='text'
              value={mainInstruments}
              fullWidth
            />
          </div>
          <div className='input-container'>
            <TextField
              onChange={(e) => setInstitute(e.target.value)}
              variant='outlined'
              color='primary'
              label='Institution Name (when applicable)'
              type='text'
              value={institute}
              fullWidth
            />
          </div>
          <div className='input-container'>
            <label>
              Renewal:
              <input
                type='checkbox'
                checked={isRenewal}
                onChange={() => setIsRenewal(!isRenewal)}
              />
            </label>
          </div>
          <div className='input-container'>
            <label>
              New Member:
              <input
                type='checkbox'
                checked={isNewMember}
                onChange={() => setIsNewMember(!isNewMember)}
              />
            </label>
          </div>
          <div className='input-container'>
            <label>
              Student:
              <input
                type='checkbox'
                checked={studentDiscount}
                onChange={() => setStudentDiscount(!studentDiscount)}
              />
            </label>
          </div>
          <div className='input-container'>
            <label>
              Performer:
              <input
                type='checkbox'
                checked={isPerformer}
                onChange={() => setIsPerformer(!isPerformer)}
              />
            </label>
          </div>
          <div className='input-container'>
            <label>
              Educator:
              <input
                type='checkbox'
                checked={isTeacher}
                onChange={() => setIsTeacher(!isTeacher)}
              />
            </label>
          </div>
          <div className='input-container'>
            <label>
              Researcher:
              <input
                type='checkbox'
                checked={isResearcher}
                onChange={() => setIsResearcher(!isResearcher)}
              />
            </label>
          </div>
          <div className='input-container'>
            <label>
              Builder:
              <input
                type='checkbox'
                checked={isMaker}
                onChange={() => setIsMaker(!isMaker)}
              />
            </label>
          </div>
          <div className='input-container'>
            <TextField
              onChange={(e) => setBiog(e.target.value)}
              multiline
              rows={8}
              variant='outlined'
              color='primary'
              label='About you'
              type='text'
              value={biog}
              fullWidth
            />
          </div>
          {user && (
            <div className='input-container photo-upload-container '>
              <div style={{ marginBottom: 8 }}>
                <Typography color='primary'>
                  Add a profile photo (optional)
                </Typography>
              </div>
              <input type='file' onChange={handleAvatarFile} />
            </div>
          )}
          <hr />
          <div style={{ marginBottom: 8 }}>
            <Typography color='primary' variant='h5'>
              Type of membership:{" "}
            </Typography>
          </div>
          {MEMBERSHIP_TYPES.map((type) => (
            <div className='input-container'>
              <label>
                {type.label}: ${type.fee}
              </label>
              <input
                type='checkbox'
                checked={membershipType === type.label}
                onChange={() => setMembership(type)}
              />
            </div>
          ))}
          <hr />
          <Typography>
            Online payment methods will appear upon completion of the required
            form fields.
          </Typography>
          {detailsValid() && !admin && !sent && (
            <>
              <hr />

              <div style={{ marginBottom: 8 }}>
                <Typography color='primary' variant='h5'>
                  Payment Options:{" "}
                </Typography>
              </div>
            </>
          )}

          {detailsValid() && !sent && !admin && (
            <div>
              <hr />
              <Typography variant='h6' color='primary'>
                Pay By Paypal
              </Typography>
              please use the button below to complete the process, paying ${fee}{" "}
              for one year of membership.
            </div>
          )}
          {admin && (
            <div style={{ margin: "auto" }}>
              <Button onClick={addToDatabase} variant='outlined'>
                Add to database
              </Button>
            </div>
          )}
          {/* {detailsValid() && (
          <>
            <Button
              onClick={() => createSubscription(newSubscription)}
              variant='contained'
              color='primary'
            >
              TEST to system
            </Button>
            <Button
              onClick={() => logSubscription(newSubscription)}
              variant='contained'
              color='primary'
            >
              Log details{" "}
            </Button>
          </>
        )} */}

          {!admin && !sent && fee > 0 && (
            <div style={{ width: "50%", margin: "auto", marginTop: 10 }}>
              {/* Pay by check button should submit all details to BE and send email to Leon advising they have signed up and will pay by check. */}

              <PayPalButton
                price={fee}
                description={"1 Year, HKSNA Membership"}
                clientId={paypalClientId}
                currency='USD'
                paySubmit={paypalPaymentSubscription}
              />
            </div>
          )}
        </div>
      )}
      {sent && (
        <Typography color='primary' variant='h6'>
          Your subscription was submitted successfully.
        </Typography>
      )}
      <hr />
      {offlineFormUrl && (
        <div style={{ marginBottom: 10, maxWidth: "70%", margin: "auto" }}>
          <Typography variant='h6' color='primary'>
            Register and Pay By Mail
          </Typography>
          <Typography variant='body1'>
            Please{" "}
            <a href={offlineFormUrl} target='_blank' download>
              {" "}
              click here to download the application form
            </a>
            , and send it together with your check, made payable to HKSNA, to:
            <br />
            <br />
            <span> Mark R. Kain</span>
            <br />
            <span>100 Magnolia Avenue </span>
            <br />
            <span>Selinsgrove, PA 17870</span>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default MembershipForm;
