const client = require("contentful").createClient({
  space: "hop419lamr8v",
  accessToken: "V4wpOrlttHe9B4ASu7AMeUNVSqTXgeBDs4BO7jPlpJM",
});

const getConferences = () =>
  client.getEntries({ content_type: "conference", order: "fields.order" }).then((response) => response.items);

const getScholarships = () =>
  client.getEntries({ content_type: "scholarship", order: "fields.order" }).then((response) => response.items);

const getScholarship = (slug) =>
  client.getEntries({ content_type: "scholarship", "fields.navigationLink": slug }).then((response) => response.items);

const getCompetitions = () => client.getEntries({ content_type: "competition" }).then((response) => response.items);

const getSingleCompetition = (slug) =>
  client.getEntries({ content_type: "competition", "fields.navigationLink": slug }).then((response) => response.items);

const getSingleConference = (slug) =>
  client.getEntries({ content_type: "conference", "sys.id": slug }).then((response) => response.items);

const getNewsletterPage = () =>
  client.getEntries({ content_type: "newsletterPage" }).then((response) => response.items);

const getNewsletters = () =>
  client.getEntries({ content_type: "newsletterPdf", order: "fields.date" }).then((response) => response.items);

const getAboutpageContent = () =>
  client.getEntries({ content_type: "aboutpageContent" }).then((response) => response.items);

const getCallForProposals = () =>
  client.getEntries({ content_type: "callForProposalsAnnouncement" }).then((response) => response.items);

const getPrivacy = () => client.getEntries({ content_type: "privacyPolicy" }).then((response) => response.items);

const getContactDestinations = () =>
  client.getEntries({ content_type: "contactDestinations" }).then((response) => response.items);

const getHomepage = () => client.getEntries({ content_type: "homePage" }).then((response) => response.items);

const getJournalpage = () => client.getEntries({ content_type: "journalPage" }).then((response) => response.items);

const getJournalIndexItems = () =>
  client.getEntries({ content_type: "journalIndexItem" }).then((response) => response.items);

export {
  getPrivacy,
  getCallForProposals,
  getCompetitions,
  getSingleCompetition,
  getConferences,
  getSingleConference,
  getNewsletterPage,
  getNewsletters,
  getAboutpageContent,
  getContactDestinations,
  getHomepage,
  getJournalIndexItems,
  getScholarship,
  getScholarships,
  getJournalpage,
};
