import React, { useEffect, useState } from "react";
import "./MembersPaneStyles.scss";
import { motion } from "framer-motion";

import { Typography, TextField, Button, Dialog } from "@material-ui/core";

import useFirebase from "../../hooks/useFirebase";
import { useSelector } from "react-redux";
import MemberDetailsModal from "./MemberDetailsModal";

const UsersPane = () => {
  const { fetchUsers } = useFirebase();
  const { members, user, paidUser } = useSelector((state) => state);
  const [displayUsers, setDisplayUsers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    fetchUsers();
    // paidUser && console.log(paidUser);
  }, []);

  const handleInputSearch = (e) => {
    const term = e.target.value.toLowerCase();
    term === ""
      ? setDisplayUsers([])
      : members &&
        setDisplayUsers(
          members.filter(
            (member) =>
              member.name.toLowerCase().includes(term) ||
              member.city.toLowerCase().includes(term) ||
              member.instruments.toLowerCase().includes(term) ||
              member.biog.toLowerCase().includes(term)
          )
        );
  };

  const handleButtonFilter = (term) => {
    members && setDisplayUsers(members.filter((member) => member[term]));
  };

  return (
    <div>
      <hr />

      <div style={{ marginTop: 16 }}>
        <Typography color='primary' variant='h4'>
          Members Directory
        </Typography>

        <TextField
          onChange={handleInputSearch}
          style={{ width: "45%", marginTop: 16 }}
          variant='outlined'
          label="Search the members' directory"
        />
        <div style={{ margin: 8 }}>
          <Button
            style={{ margin: 8 }}
            onClick={() => handleButtonFilter("teacher")}
            variant='contained'
            color='primary'
          >
            {" "}
            Teachers
          </Button>
          <Button
            style={{ margin: 8 }}
            onClick={() => handleButtonFilter("maker")}
            variant='contained'
            color='primary'
          >
            {" "}
            Makers & Technicians
          </Button>
          <Button
            style={{ margin: 8 }}
            onClick={() => setDisplayUsers([])}
            variant='contained'
            color='primary'
          >
            {" "}
            clear search
          </Button>
          <Button
            style={{ margin: 8 }}
            onClick={() => setDisplayUsers(members)}
            variant='contained'
            color='primary'
          >
            {" "}
            show all{" "}
          </Button>
        </div>
        <div className='member-cards-container'>
          {displayUsers.map((user, i) => {
            // console.log(user);
            return (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: i / 5 }}
                key={i}
                onClick={() => setSelectedMember(user)}
                className='member-card'
              >
                <div className='member-card__avatar'>
                  {user.avatar ? (
                    <img src={user.avatar} />
                  ) : (
                    <div className='avatar-placeholder'>
                      <>
                        <Typography>
                          {user.name.split(" ")[0].slice(0, 1)}
                        </Typography>
                        {user.name.split(" ").length > 1 && (
                          <Typography>
                            {user.name.split(" ")[1].slice(0, 1)}
                          </Typography>
                        )}
                      </>
                    </div>
                  )}
                </div>
                <Typography className='members-list-info'>
                  {user.name}
                </Typography>
                <Typography className='members-list-info'>
                  {user.instruments}
                </Typography>
                <Typography className='members-list-info'>
                  {user.city}
                </Typography>
                {/* 
                  {user.teacher && (
                    <Typography className='members-list-info chip'>
                      Teacher
                    </Typography>
                  )} */}
                {/* <Typography>{user.address1}</Typography>
            <Typography>{user.address2}</Typography>
             */}
              </motion.div>
            );
          })}
        </div>
      </div>

      <MemberDetailsModal
        member={selectedMember}
        open={selectedMember}
        onClose={() => setSelectedMember(null)}
      />
    </div>
  );
};

export default UsersPane;
