import React, { useState, useEffect } from "react";
import "./ConferenceRegistrationModalStyles.scss";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";
import PayPalButton from "react-paypal-smart-button";

import { Dialog, Box, TextField, Button, Typography } from "@material-ui/core";
import { STATES } from "../../constants/states";
import Spinner from "../../components/Spinner/Spinner";
import useSendEmail from "../../hooks/useSendEmail";
import ReCaptcha from "../ReCaptcha/ReCaptcha";
import { getContactDestinations } from "../../contentful";

const ConferenceRegistrationModal = () => {
  const dispatch = useDispatch();
  const {
    conferenceRegistration,
    selectedConference,
    loading,
    paypalClientId,
  } = useSelector((state) => state);

  const { sendMail, sent } = useSendEmail();
  const [userEnteredConferenceTitle, setUserEnteredConferenceTitle] = useState(
    ""
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const recipient = "treasurer@hksna.org, rbloomfield@masters.edu";
  const [recaptcha, setRecaptcha] = useState(false);
  const [fee, setFee] = useState(null);
  const [offlineFormUrl, setOfflineFormUrl] = useState("");
  // const [transportTypeArrival, setTransportTypeArrival] = useState("");
  // const [transportTypeDep, setTransportTypeDep] = useState("");
  // const [arrivalDetails, setArrivalDetails] = useState("");
  // const [departureDetails, setDepartureDetails] = useState("");

  const [recipients, setRecipients] = useState("secretary@hksna.org");
  useEffect(() => {
    getContactDestinations().then((data) => {
      const contactForm = data.find(
        (data) => data.fields.place === "Conference Registration"
      );
      if (contactForm) {
        setRecipients(contactForm?.fields.email?.join(", "));
        setOfflineFormUrl(contactForm?.fields.form?.fields.file.url);
      }
    });
  }, []);

  // if there is an & in the string, it breaks the query string parsing
  const replaceAmpersand = (string) => string.replace("&", "and");

  const subject = `Conference attendance application from ${firstName} ${lastName} `;

  const body2 = `<div><h4>Conference: ${selectedConference?.title}${userEnteredConferenceTitle} - attendee application.</h4><p>Name: ${firstName} ${lastName}</p><p>Email: ${email}</p><p>Address:</p><p>${street1}</p><p>${street2}</p><p>${city}</p><p>${province}</p><p>${country}</p><p>Payment by Paypal for the amount of $${fee}</p></div>`;

  const confirmationBody = `<div><p>Dear ${firstName} ${lastName}.</p><p>Many thanks for your conference subscription.  If you paid via Paypal, you should receive an email from Paypal to confirm your payment was recieved.</p><p>Should you have any queries, please reach out to use via email at secretary@hksna.org</p></div>`;

  const readyToSend = () => {
    if (firstName && lastName && email && recaptcha && fee) return true;
  };

  const paypalPaymentSubscription = () => {
    // send email to HKSNA treasurer and chair
    sendEmailforPaypalSignup();
  };

  const sendEmailforPaypalSignup = async () => {
    sendMail(replaceAmpersand(body2), subject, recipients);
    sendMail(confirmationBody, subject, email);
  };

  const handleFeeSelect = (e) => setFee(e.target.value);

  return (
    <Dialog
      open={conferenceRegistration}
      onClose={() =>
        dispatch({ type: ACTIONTYPES.TOGGLE_CONFERENCE_REGISTRATION_MODAL })
      }
    >
      <div className='proposals-modal'>
        <Box mb={1}>
          <Typography variant='h5' color='primary'>
            Conference Attendee Registration
          </Typography>
          {selectedConference && (
            <Typography variant='h6'>
              Conference: {selectedConference.title}
            </Typography>
          )}
        </Box>
        <hr />
        <div style={{ marginBottom: 10 }}>
          <Typography variant='h6' color='primary'>
            Register and Pay By Mail
          </Typography>
          <Typography variant='body1'>
            Please{" "}
            <a href={offlineFormUrl} target='_blank' download>
              {" "}
              click here to download the application form
            </a>
            , and send it together with your check, made payable to HKSNA, to:
            <br />
            <br />
            <span> Mark R. Kain</span>
            <br />
            <span>100 Magnolia Avenue </span>
            <br />
            <span>Selinsgrove, PA 17870</span>
          </Typography>
        </div>
        <hr />
        <Typography variant='h6' color='primary'>
          Register and Pay Online{" "}
        </Typography>
        {!sent && !loading && (
          <form className='proposals-form'>
            {!selectedConference && (
              <div className='input'>
                <TextField
                  name='Event'
                  className='input'
                  variant='outlined'
                  label='Conference date / title'
                  onChange={(e) =>
                    setUserEnteredConferenceTitle(e.target.value)
                  }
                />
              </div>
            )}
            <div className='input'>
              <TextField
                name='First`-name'
                className='input'
                variant='outlined'
                label='First name'
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='input'>
              <TextField
                name='Last_name'
                className='input'
                variant='outlined'
                label='Last name'
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className='input'>
              <TextField
                name='Email'
                className='input'
                variant='outlined'
                label='Email'
                type='email'
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='input'>
              <TextField
                name='Address1'
                className='input'
                variant='outlined'
                label='Street Address 1'
                onChange={(e) => setStreet1(e.target.value)}
              />
            </div>
            <div className='input'>
              <TextField
                name='Address2'
                className='input'
                variant='outlined'
                label='Street Address 2'
                onChange={(e) => setStreet2(e.target.value)}
              />
            </div>
            <div className='input'>
              <TextField
                name='City'
                className='input'
                variant='outlined'
                label='City'
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div className='input'>
              <TextField
                name='State / Provice'
                className='input'
                variant='outlined'
                label='State / Province'
                onChange={(e) => setProvince(e.target.value)}
              />
            </div>
            <div className='input'>
              <TextField
                name='Country'
                className='input'
                variant='outlined'
                label='Country'
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>

            <hr />

            <hr />
            <ReCaptcha onSuccess={() => setRecaptcha(true)} />
            <hr />
            <div style={{ marginBottom: 8 }}>
              <Typography color='primary' variant='h5'>
                Payment Options:{" "}
              </Typography>

              <div style={{ marginTop: 10 }}>
                <Typography color='primary' variant='body1'>
                  Fee to be paid:
                </Typography>
                <div>
                  <label>Non-members ($300):</label>
                  <input
                    type='radio'
                    name='fee'
                    value={300}
                    onChange={handleFeeSelect}
                  />
                </div>
                <div>
                  <label>Members ($250):</label>
                  <input
                    type='radio'
                    name='fee'
                    value={250}
                    onChange={handleFeeSelect}
                  />
                </div>
                <div>
                  <label>Student/Associate members ($75):</label>
                  <input
                    type='radio'
                    name='fee'
                    value={75}
                    onChange={handleFeeSelect}
                  />
                </div>
                <div>
                  <label>Presenters ($150):</label>
                  <input
                    type='radio'
                    name='fee'
                    value={150}
                    onChange={handleFeeSelect}
                  />
                </div>
                <div>
                  <label>Student Presenters ($50):</label>
                  <input
                    type='radio'
                    name='fee'
                    value={50}
                    onChange={handleFeeSelect}
                  />
                </div>
                {/* <div>
                  <label>One-day rate ($150):</label>
                  <input
                    type='radio'
                    name='fee'
                    value={150}
                    onChange={handleFeeSelect}
                  />
                </div> */}
              </div>
              <hr />
              <Typography>
                Payment methods will appear upon completion of the above form.
              </Typography>
            </div>
            {readyToSend() && (
              <div>
                {/* <Button
                  style={{ marginTop: 15 }}
                  onClick={checkPaymentSubscription}
                  variant='contained'
                  color='primary'
                  disabled={!readyToSend()}
                >
                  Register Now, then pay by check
                </Button> */}
                <hr />
                <div>
                  <hr />
                  <Typography variant='h6' color='primary'>
                    Pay By Paypal
                  </Typography>
                  please use the button below to complete the process. Upon
                  successful payment, your registration will be sent to us.
                </div>
                <div
                  style={
                    !readyToSend()
                      ? { pointerEvents: "none", opacity: 0.5 }
                      : {}
                  }
                >
                  <PayPalButton
                    price={fee}
                    description={`Conference subscription for ${fee}`}
                    clientId={paypalClientId}
                    currency='USD'
                    paySubmit={paypalPaymentSubscription}
                  />
                </div>
              </div>
            )}

            <div>
              <Button
                variant='outlined'
                onClick={() =>
                  dispatch({
                    type: ACTIONTYPES.TOGGLE_CONFERENCE_REGISTRATION_MODAL,
                  })
                }
              >
                cancel
              </Button>
            </div>
          </form>
        )}
        {loading && <Spinner />}
        {sent && (
          <div style={{ textAlign: "center" }}>
            <Typography
              variant='h6'
              color='primary'
              style={{ textAlign: "center" }}
            >
              Thank you for your application.
            </Typography>

            <div style={{ marginTop: 35 }}>
              <Button
                onClick={() =>
                  dispatch({
                    type: ACTIONTYPES.TOGGLE_CONFERENCE_REGISTRATION_MODAL,
                  })
                }
                variant='outlined'
                color='primary'
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ConferenceRegistrationModal;
