import React, { useState, useEffect } from "react";
import { getNewsletterPage, getNewsletters } from "../../contentful";
import marked from "marked";
import "./NewslettersPageStyles.scss";

import Typography from "@material-ui/core/Typography";

import PageTitle from "../../components/PageTitle";
import PDFDownloadItem from "../../components/PDFDownloadItem";
import Helmet from "../../components/Helmet";

const NewslettersPage = () => {
  const [text, setText] = useState(null);
  const [newsletters, setNewsletters] = useState([]);

  useEffect(() => {
    getNewsletterPage().then((data) => setText(data[0].fields.text));
    getNewsletters().then((data) => setNewsletters(data.reverse()));
  }, []);
  return (
    <div className='container'>
      <Helmet title={`HKSNA | Newsletters `} />

      <PageTitle title='Newsletters' />
      {text && (
        <Typography dangerouslySetInnerHTML={{ __html: marked(text) }} />
      )}
      <hr />
      <Typography variant='h5' color='primary'>
        Past Editions
      </Typography>
      <Typography>
        Scroll through the links below to download past editions
      </Typography>
      <div className='pdf-scroll-window'>
        {newsletters.map((pdf) => (
          <PDFDownloadItem
            text={pdf.fields.title}
            file={pdf.fields.pdf.fields.file.url}
          />
        ))}
      </div>
    </div>
  );
};

export default NewslettersPage;
