import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./LoginPageStyles.scss";

import {
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

import useFirebase from "../../hooks/useFirebase";
import PageTitle from "../../components/PageTitle";
import LoginForm from "../../components/LoginForm";
import Helmet from "../../components/Helmet";
import MyProfile from "../../components/MyProfile/MyProfile";
import Spinner from "../../components/Spinner";
import MembershipForm from "../../components/MembershipForm";

const LoginPage = () => {
  const {
    createUser,
    login,
    createSubscription,
    renewSubscription,
    googleSignIn,
  } = useFirebase();

  const {
    user,
    paidUser,
    loading,
    accountExpired,
    paypalClientId,
  } = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [hasAccount, setHasAccount] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    user && console.log(user.avatar);
    console.log(paidUser);
    // user.avatar !== null && setAvatar(user.avatar);
    // paidUser && history.push("/membership");
  }, [paidUser, user]);

  const handleSignup = () => {
    createUser(email, password, name);
    console.log(email, password);
  };

  const handleLogin = () => {
    login(email, password);
  };

  return (
    <div>
      <Helmet title={`HKSNA | Login/Signup `} />

      {loading && (
        <p>
          <Spinner />{" "}
        </p>
      )}
      {paidUser && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link style={{ textDecoration: "none" }} to='/membership'>
            <Button variant='contained' color='primary'>
              To Membership Page
            </Button>
          </Link>
        </div>
      )}
      {!user && !loading && (
        <Typography>
          Please sign in to access members-only content on this website. If you
          do not have an account, you may register for free by entering your
          name, email and choosing a password, after which you can subscribe to
          a membership plan. All plans are valid for 1 year from the date of
          payment.
        </Typography>
      )}

      {!user && !loading && (
        <LoginForm
          hasAccount={hasAccount}
          setName={setName}
          name={name}
          setEmail={setEmail}
          email={email}
          setPassword={setPassword}
          password={password}
          handleSignup={handleSignup}
          handleLogin={handleLogin}
          googleSignIn={googleSignIn}
          setHasAccount={setHasAccount}
        />
      )}

      {user && (
        <div>
          {/* <Typography variant='h6'>Welcome to HKSNA, {user.name}</Typography> */}
          {!paidUser && !loading && (
            <>
              <div style={{ textAlign: "center", margin: 20 }}>
                <Typography variant='body1' color='primary'>
                  Thank you for registering on our system. <br />
                  Please use this button to pay for one year of members-only
                  content.
                </Typography>
              </div>
              <MembershipForm />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default LoginPage;
