import React, { useState } from "react";
import "./DonationsModalStyles.scss";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";
import PayPalButton from "react-paypal-smart-button";

import {
  Dialog,
  Typography,
  TextField,
  MenuItem,
  Button,
  Box,
} from "@material-ui/core";

const DonationsModal = () => {
  const [selectedTier, setSelectedTier] = useState(null);
  const dispatch = useDispatch();
  const { donationsModal, paypalClientId } = useSelector((state) => state);
  const [paid, setPaid] = useState(false);

  const donations = [
    {
      label: "Main",
      amount: 0.5,
    },
    { label: "ClickscaleBechtel", amount: 0.6 },
    { label: "Funaro", amount: 0.2 },
    { label: "Alienor", amount: 0.1 },
  ];

  const handleTierSelect = (tier) => {
    setSelectedTier(tier);
  };

  return (
    <Dialog
      open={donationsModal}
      onClose={() => dispatch({ type: ACTIONTYPES.TOGGLE_DONATIONS_MODAL })}
    >
      <div className='modal-container'>
        <Typography variant='h5' color='primary'>
          We welcome all donations
        </Typography>
        {!paid && (
          <>
            <Typography variant='body1'>
              Choose the amount/reason you would like to donate from the
              dropdown menu below.{" "}
            </Typography>
            <Box mt={2} mb={2}>
              <TextField
                variant='outlined'
                fullWidth
                label='Please select a donation type'
                select
                value={selectedTier}
              >
                {donations.map((tier) => (
                  <MenuItem key={tier} onClick={() => handleTierSelect(tier)}>
                    ${tier.amount}: {tier.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {selectedTier && (
              <>
                <Typography variant='h5'>
                  You have selected: {selectedTier.label}: $
                  {selectedTier.amount}
                </Typography>
                <div style={{ marginTop: 8 }}>
                  <PayPalButton
                    price={selectedTier.amount}
                    description={selectedTier.label}
                    clientId={paypalClientId}
                    currency='USD'
                    paySubmit={() => setPaid(true)}
                  />
                </div>
              </>
            )}
          </>
        )}
        {!paid && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography color='primary' variant='h5'>
              Thank you for your donation
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() =>
                dispatch({ type: ACTIONTYPES.TOGGLE_DONATIONS_MODAL })
              }
            >
              close
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default DonationsModal;
