import React, { useState } from "react";
import "./TabsStyles.scss";
import marked from "marked";
import { Typography } from "@material-ui/core";

const Tabs = ({ items }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  if (!items) return null;
  return (
    <div className='tabs-container'>
      <div className='tabs__row'>
        {items.map((item, i) => (
          <button
            className={selectedIndex === i ? "tabs__item--selected" : ""}
            onClick={() => setSelectedIndex(i)}
          >
            <Typography>{item.title}</Typography>
          </button>
        ))}
      </div>
      <div
        className='tabs__content'
        dangerouslySetInnerHTML={{
          __html: marked(items[selectedIndex].content),
        }}
      />
    </div>
  );
};

export default Tabs;
