import React, { useState, useEffect } from "react";
import { getSingleConference } from "../../contentful";
import { useParams } from "react-router-dom";
import marked from "marked";
import "./ConferenceDetailsPageStyles.scss";
import { SRLWrapper } from "simple-react-lightbox";
import { useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";

import { Typography, Button } from "@material-ui/core";
import PDFDownloadItem from "../../components/PDFDownloadItem";
import Helmet from "../../components/Helmet";
import PageTitle from "../../components/PageTitle";
import Accordion from "../../components/Accordion/Accordion";
import Gallery from "../../components/Gallery/Gallery";
const ConferenceDetailsPage = () => {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleConference(id).then((data) => setDetails(data[0]?.fields));
  }, [id]);

  const startingDate = new Date(details.startDate);
  const endDate = new Date(details.endDate);
  console.log(details);
  console.log(details.photoGallery);
  return (
    <div className='container'>
      <Helmet title={`HKSNA | Conference: ${details?.title} `} />
      {details.image && (
        <div style={{ width: "70%", margin: "auto" }}>
          <img
            className='header-section__image'
            src={details.image.fields.file.url}
            style={{ maxWidth: "100%" }}
          />
        </div>
      )}
      <PageTitle title={details?.title} />

      <div className='header-section'>
        <div>
          {details?.venue && (
            <Typography
              dangerouslySetInnerHTML={{ __html: marked(details?.venue) }}
            ></Typography>
          )}
          <Typography variant='h5' color='secondary'>
            {/* {moment(conference.startDate).format("ddd MMM Do")} */}
            {details?.startDate}
            {details.endDate && (
              <span>
                - {details?.endDate}
                {/* - {moment(conference.endDate).format("ddd MMM Do YYYY")} */}
              </span>
            )}
          </Typography>

          {/* <Typography color='secondary'>
            {startingDate.toDateString()}
            {details.endDate && <span> - {endDate.toDateString()}</span>}
          </Typography> */}
          {!details.archived && (
            <Button
              color='primary'
              variant='outlined'
              style={{ margin: "20px 0" }}
              onClick={() =>
                dispatch({
                  type: ACTIONTYPES.TOGGLE_CONFERENCE_REGISTRATION_MODAL,
                  payload: details,
                })
              }
            >
              Register
            </Button>
          )}
          {details.details && (
            <>
              <hr />
              <Typography
                variant='body1'
                dangerouslySetInnerHTML={{ __html: marked(details.details) }}
              />
            </>
          )}
          {details.generalInformation && (
            <>
              <Accordion
                defaultOpen={true}
                mainSection
                header='General Information'
                content={details.generalInformation}
              />
            </>
          )}
        </div>
      </div>
      {details.dailyInformation && (
        <>
          <hr />
          <Typography color='primary' variant='h5'>
            Daily Schedules
          </Typography>
        </>
      )}
      {details.pdFs && (
        <div>
          <hr />
          <Typography>Download the Programbook and other PDFs</Typography>
          {details.pdFs.map((pdf) => (
            <PDFDownloadItem
              key={pdf.sys.id}
              text={pdf.fields.title}
              file={pdf.fields.file.fields.file.url}
            />
          ))}
          <hr />
        </div>
      )}

      {details.dailyInformation &&
        details.dailyInformation.map((day) => {
          const date = new Date(day.fields.date);
          return (
            <div key={day.sys.id}>
              <Accordion
                header={day.fields.date}
                content={day.fields.details}
              />
            </div>
          );
        })}
      <div className='conference-videos-container'>
        {details.youtubeVideos &&
          details.youtubeVideos.map((video) => (
            <div>
              <div key={video.sys.id} className='conference-video-card'>
                <section
                  style={{ height: "85%" }}
                  dangerouslySetInnerHTML={{
                    __html: marked(video.fields.youtubeCode),
                  }}
                />
              </div>
              <Typography className='video-title' variant='subtitle2'>
                {video.fields.title}
              </Typography>
            </div>
          ))}
      </div>

      <div style={{ marginTop: 50 }}>
        <Gallery images={details.photoGallery} />
      </div>
    </div>
  );
};

export default ConferenceDetailsPage;
