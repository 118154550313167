import React, { useState, useEffect } from "react";
import "./MyProfileStyles.scss";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONTYPES } from "../../store/ActionTypes";
import useFirebase from "../../hooks/useFirebase";
import firebase from "../../firebase";
import { Typography, Dialog, TextField, Button } from "@material-ui/core";
import Spinner from "../Spinner";
const MyProfile = () => {
  const dispatch = useDispatch();
  const { updateProfile, changePassword } = useFirebase();
  const { paidUser, user, loading } = useSelector((state) => state);
  const [editProfile, setEditProfile] = useState(false);
  const [name, setName] = useState("");
  const [instruments, setInstruments] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [avatar, setAvatar] = useState("");
  const [updating, setUpdating] = useState(false);
  const [student, setStudent] = useState(false);
  const [maker, setMaker] = useState(false);
  const [biog, setBiog] = useState("");
  const [teacher, setTeacher] = useState(false);

  console.log("////// FULL USER", paidUser);

  const handleAvatarFile = async (e) => {
    setUpdating(true);
    const file = e.target.files[0];
    // console.log(file);
    // var filesize = (file.size / 1024 / 1024).toFixed(2); // MB
    const storageRef = firebase.storage().ref(`members/${paidUser.id}/avatars`);
    const fileRef = storageRef.child("avatar.jpg");
    await fileRef.put(file);
    const newAvatar = await fileRef.getDownloadURL();
    setAvatar(newAvatar);
    firebase
      .auth()
      .currentUser.updateProfile({ photoURL: await fileRef.getDownloadURL() });

    dispatch({
      type: ACTIONTYPES.SET_USER,
      payload: { ...user, avatar: newAvatar },
    });
    setUpdating(false);
  };

  useEffect(() => {
    setName(paidUser.name);
    setInstruments(paidUser.instruments);
    setAddress1(paidUser.address1);
    paidUser.address2 && setAddress2(paidUser.address2);
    setCity(paidUser.city);
    setState(paidUser.state);
    setAvatar(user.avatar);
    setTeacher(paidUser.teacher);
    paidUser.student && setStudent(paidUser.student);
    setBiog(paidUser.biog);
    paidUser.maker && setMaker(paidUser.maker);
  }, [paidUser]);

  const handleUpdateProfile = () => {
    const updatedUser = {
      ...paidUser,
      name,
      instruments,
      address1,
      address2,
      city,
      state,
      avatar,
      student: student,
      maker: maker,
      biog: biog,
      teacher: true,
    };
    updateProfile(updatedUser, paidUser.firebaseKey);
    setEditProfile(false);
  };

  const inputStyle = {
    marginBottom: 16,
  };
  return (
    <div>
      <hr />
      <div>
        <Typography variant='h6' color='primary'>
          My Profile
        </Typography>
        {!editProfile && (
          <>
            <div className='profile-pane'>
              <div>
                <Typography>Name: {name}</Typography>
                <Typography>Instruments: {instruments}</Typography>
                {student && <Typography>Student</Typography>}
                {teacher && <Typography>Teacher</Typography>}
                <Typography>Address 1: {address1}</Typography>
                {paidUser.address2 && (
                  <Typography>Address 2: {address2}</Typography>
                )}
                <Typography>City: {city}</Typography>
                {paidUser.state && <Typography>State: {state}</Typography>}
                <Typography>
                  Account active since:{" "}
                  {new Date(paidUser.createdAt).toDateString()}
                </Typography>
                <hr />
              </div>
              <div className='avatar-container'>
                {avatar && <img src={avatar} />}
              </div>
            </div>
            <Typography color='primary'>About Me</Typography>
            <Typography>{biog}</Typography>
            <div style={{ margin: "20px 0" }}>
              <Button
                onClick={() => setEditProfile(true)}
                style={{ margin: "auto" }}
                variant='outlined'
                color='primary'
              >
                Edit Profile
              </Button>
            </div>
            <Button
              onClick={() => changePassword(paidUser.email)}
              style={{ margin: "auto" }}
              variant='contained'
              color='primary'
            >
              Change Password
            </Button>
          </>
        )}

        {editProfile && (
          <div className='update-profile-pane'>
            <div className='update-profile-form'>
              <Typography variant='h6' color='primary'>
                Add / update your profile
              </Typography>
              <TextField
                style={inputStyle}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                style={inputStyle}
                value={instruments}
                onChange={(e) => setInstruments(e.target.value)}
              />
              <TextField
                style={inputStyle}
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
              />
              <TextField
                style={inputStyle}
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              />
              <TextField
                style={inputStyle}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <TextField
                style={inputStyle}
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
              <TextField
                value={biog}
                label='Biog'
                multiline
                onChange={(e) => setBiog(e.target.value)}
              />
              <hr />

              <Button
                onClick={handleUpdateProfile}
                variant='outlined'
                color='primary'
              >
                Save and Close
              </Button>
            </div>
            <div className='update-avatar-form'>
              <div className='avatar-container'>
                {avatar && !updating && <img src={avatar} />}
                {updating && <Spinner />}
              </div>
              <Typography variant='h6' color='primary'>
                Add / update profile picture
              </Typography>
              <div
                style={{
                  marginBottom: 16,
                }}
              >
                <input type='file' onChange={handleAvatarFile} />
              </div>
              <Typography color='primary' variant='subtitle2'>
                automatically saves when a file is selected
              </Typography>
              <Button
                onClick={() => setEditProfile(false)}
                variant='outlined'
                color='primary'
                fullWidth
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyProfile;
